body,
html {
    font-family: Roboto, sans-serif;
    font-size: 14px;
    color: rgba(52, 73, 94, 0.87);
}

.h1, .h2 {
    margin-top: 0;
}

.h1 {
    font-family: Opens Sans, sans-serif;
    font-size: 48px;
    color: #4e6173;
    text-transform: uppercase;
}

.h1--roboto {
    font-family: Roboto, sans-serif;
}

.h2 {
    font-size: 24px;
}

.h2--bold {
    font-family: RobotoMedium, sans-serif;
}

.h2--orange {
    color: #f90;
}
