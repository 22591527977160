
.hout header {
    margin-bottom: 16px;
}

.hout header .btn-group {
    margin-bottom: 3px;
}


.hout-list {
    flex-basis: 30%;
    flex-shrink: 0;
}

.hout-list > * {
    display: block;
    overflow: auto;
    max-height: 100%;
}

.hout-item {
    display: flex;
    flex-direction: column;
    flex-basis: 70%;
    min-height: 0px;
    padding-left: 20px;

}

.hout-item-header {
    font-size: 160%;
    margin-top:10px;
}

.hout-controls {
    display: block;
    margin-bottom: 16px;
}

.hout-checklist-container {
    display: flex;
    height: 100%;
}

.hout-checklist {
    height: 100%;
    width:100%;
}

