
logoPanel, table>caption{
	background-image: linear-gradient(to bottom,#8CC63F,#557a23)!important;
}

taskFullInfo table>caption{background-image: none !important}

checklist zones .container-element h3 {
    background-image: linear-gradient(to bottom,#8CC63F,#557a23)!important;
}
checklist zones .container-element h3.collapsed {
    background-image: linear-gradient(to top,#8CC63F,#557a23)!important;
}

ul.guide>li>item>div:not(.arrow){
	background-image: linear-gradient(to bottom,#557a23,#8CC63F)!important;
}


.btn-primary, button.branded {
    background-color: #567929;
    border-color: #567929;
}

.btn-primary:active,
.btn-primary.active{
    background-color: #8DC449 !important;
    border-color: #8DC449 !important;
}

.btn-primary:hover,
.btn-primary:focus {
  background-color: #88AB5B !important;
  border-color: #88AB5B !important;
}

.btn-primary:disabled, .btn-primary:disabled:hover {
	background-color: #C9D0D6 !important;
	border-color: #C9D0D6 !important;
}

.btn-primary.reverse, button.branded.reverse {background-color: #4E6173;border-color: #4E6173;color:white;}
.btn-primary.reverse:active, .btn-primary.reverse.active{color:white !important;background-color: #4B87BE !important;; border-color: #4B87BE !important;;}
.btn-primary.reverse:hover,.btn-primary.reverse:focus {color:white !important;background-color: #6F89A3 !important;;border-color: #6F89A3  !important;}
.btn-primary.reverse:disabled{background-color: #C9D0D6;border-color: #C9D0D6;}

.btn-default{color: #4E6173;border-color: #4E6173;}
.btn-default:hover,.btn-default:focus {color: #6F89A3;border-color: #6F89A3;background-color:white;}
.btn-default:active, .btn-default.active{color: #4B87BE !important; border-color: #4B87BE !important;background-color:white  !important;}
.btn-default :disabled{color: #C9D0D6;border-color: #C9D0D6;}

taskFullInfo table>caption, page#taskFull shortInfo{
	color: #557a23;
}

panel>header {
    color: #557a23;
}

.app-btn-group button:first-child{
	border-left: 1px solid #557a23 !important;
}
.app-btn-group button{
	border: 1px solid #557a23 !important;
    background-color: #557a23 !important;
}
.app-btn-group button.selected{
	background-color: #8CC63F !important;
}

.app-btn-group button:hover{
	background-color: #8CC63F !important;
}

controls input[type='checkbox']:checked, form.filters input[type='checkbox']:checked, .app-checkbox:checked {
    background-color: #103A81 !important;
    border: 1px solid #103A81 !important;
}

table.styled tbody tr:hover{background-color: rgba(16,58,129,.1)}
table.styled tbody tr.active td{background-color: rgba(16,58,129,.3) !important}
html table.styled td{border: 1px solid #EBEFF2}


body .reportFilters filter .form-control{
	border-color: #557a23 !important;
}

body .reportFilters filter .select2-container-multi .select2-choices, body .reportFilters filter .input-group-btn button{
	border-color: #557a23 !important;
}

body .reportFilters filter .input-group-btn button:hover{
	color: #557a23 !important
}

/*treecontrol.tree-light li.tree-collapsed>i.tree-branch-head:after{
	border-color: #557a23 !important;
	color: #557a23 !important

}*/

.nav-tabs > li.active > a {
    box-shadow: 0 -2px 0 #557a23;
}

pagination.pagination>.active>a,.executors-tree search > button {
    background-color: #557a23!important;
}

mdAuditLogo.fixprice {
      display: block;
    height: 125px;
    width: 200px;
    background: url(../img/fp_logo.png) no-repeat center;
    background-size: contain;
}

.select2-results .select2-highlighted , .select2-container-multi .select2-choices .select2-search-choice {
    background-color: #557a23;
    border-color: #103A81;
    white-space: nowrap;
}

.jarviswidget > header, body .reportFilters filter .ui-select-choices-row:hover {
    color: #103A81 !important
}

controls input[type='radio']:checked, form.filters input[type='radio']:checked, .app-radio:checked{
	border: 1px solid #103A81 !important
}

controls input[type='radio']:checked:after, form.filters input[type='radio']:checked:after, .app-radio:checked:after {
    background-color: #103A81 !important;
}

.tabs-nav li:after{
	background-color: #103A81 !important;
}
.paramsList block>blockName:hover>toggle {
    color: #103A81  !important;
}

table.styled thead td>sort.active:after, table.styled thead td>sort.active.reverse:before{color: #103A81  !important;}
head.sortableRich sort.active {
    color: gray !important;
}

.ui-select-choices-row.active{
	color: #557a23  !important;
}

nav > ul > li > a > i,nav > ul > li > a:hover > i{
    border-color:#103A81  !important;
}
nav > ul > li > a > i:before{
    color:#103A81;
}

a,a:hover{color:#103A81;;}

controls input[type='text']:focus, controls input[type='number']:focus, form.filters input[type='text']:focus, form.filters input[type='number']:focus, form.filters select:focus, .app-input:focus {
    border: 1px solid #103A81 !important;
}

.radio-switcher--buttons.active {
    font-family: RobotoMedium, sans-serif;
    color: #fff;
    background-color: #103A81;
}
.radio-switcher--buttons .radio-switcher__item.active { background-color: #557a23 !important;}


.btn__create {
    background-image: linear-gradient(180deg, #557a23 4.17%, #8CC63F  100%) !important;
}

.btn__create:hover{
    background-image: linear-gradient(180deg, #8CC63F 4.17%, #557a23 100%) !important;
}
.btn__create:active {
    background-image: linear-gradient(180deg, #8CC63F 4.17%, #103A81 100%) !important;
}

.md-checkbox input[type='checkbox']:not(:checked) + .md-checkbox__field, .select--gray .ui-select-match .ui-select-toggle:hover, .select--gray .ui-select-match .ui-select-toggle:focus, .select--gray .ui-select-match .ui-select-toggle:active{border-color:#103A81 !important}

 .executors-tree__header{color: #557a23 !important;}

pokazometr>line>div>fill,page#charts>panel>content>settedFilters item{background-color:#8CC63F !important}

.md-checkbox input[type='checkbox']:checked + .md-checkbox__field {
    background-color: #557a23 !important;
    border-color: #103A81 !important;
}

.executors-tree__selected-count{
    background-color: #103A81 !important;
}

#downloadApp logo{background-size: contain !important;background: url(../../img/downloadApp/fp-logo.png) no-repeat center center  !important;;height: 89px !important;
    margin-bottom: 17px;}

#downloadApp bgWrapper{display:block;padding-top:10px;
    background: url(../../img/downloadApp/bg-border.png) no-repeat 0 calc(100% + 80px),
                linear-gradient(246.86deg,#93d43d .31%,#51bd75 49.62%,#2242b3 98.27%) no-repeat 0 100%,
                white  !important;
}

#downloadApp .bottomLogo {padding: 10px 0 !important;height: 69px !important;}

@media screen and (min-width: 760px) {
	#downloadApp bgWrapper { background: url(../../img/downloadApp/bg-top.png) no-repeat 0 100%,
																			 url(../../img/downloadApp/bg-border.png) no-repeat 0 100%,
																			 linear-gradient(246.86deg,#93d43d .31%,#51bd75 49.62%,#2242b3 98.27%) no-repeat 0 100%,
																			 white  !important;
													 background-size: contain !important; }
}

.app-btn {background-color: #557a23 !important;}


/* dashboard */
#createPopup header strong, #createPopup header h3{color:#103A81 !important;}

.task-new__form h2>strong{color:#557a23 !important;}

rotator{background: url("../img/ajax-loaders/loader-intersection.gif") !important}

ul[uib-dropdown-menu] li:hover{color: green !important;}

#dashboard3 td.ordType.active, #dashboard3 thead td.day.today, #dashboard3 thead td.day.today, #dashboard3 thead td.day.today>name  {color:#8DC449 !important;}


#dashboard3 thead td.day{color:#C9D0D6;}
#dashboard3 thead td.day>name{color:#4E6173}
#dashboard3 td.search .btn:hover{color:#4E6173;}

.task-new__form strong { color: #557a23;}


/*Пока переехало сюда для более простого мёржа
Надо бы что-то придумать куда склардировать только для fp спецефичный код*/
page#formFullView .tableFixedLayout{    table-layout: fixed;}
page#formFullView .tableFixedLayout tr>td:first-of-type{width:400px;}
@media only screen and (max-width: 1100px) {
    page#formFullView .tableFixedLayout tr>td:first-of-type{width:200px;}
}

page#formFullView .theadWrapper{padding-right:15px;}
page#formFullView .tableFixedLayout:first-of-type{margin-bottom:0px;}
page#formFullView .scrollable{height:0;margin-top:0;overflow-y: auto;padding: 0 18px 0 0;}
page#formFullView>panel {flex-grow: 1;}

page#formFullView>panel main {height: 100%;flex-grow: 1;display: flex;flex-direction: column;align-items: stretch;position: relative;}
page#formFullView>panel main>.tableWrapper {overflow: auto;flex-grow: 1;position: absolute;top: 52px;left: 0;bottom: 0;right: 0;}



page.task-full-view>header>.breadcrumbs {margin-top: 14px;}

page.task-full-view .fullInfo {flex-shrink: 0;position: relative;overflow: hidden;max-height: 75px;display: flex;flex-wrap: wrap;padding: 20px;margin-bottom: 22px;box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.24);transition: max-height 0.3s ease-in-out;}
page.task-full-view .fullInfo.expanded {max-height: 1000px;}

page.task-full-view .fullInfo .linkedTaskWrapper {width: 100%;text-align: right;margin-top: 20px;}

page.task-full-view .toggle-arrow {width: 24px;height: 13px;position: absolute;right: 20px;top:28.5px;transform: rotate(180deg);background: url("../../img/arrow.png") no-repeat;transition: transform 0.3s ease-in-out;}
page.task-full-view .toggle-arrow:hover {cursor: pointer;}
page.task-full-view .toggle-arrow.up {transform: rotate(0deg);}

page.task-full-view .fullInfo>.title {width: 100%;margin-bottom: 20px;font-size: 22px;color: #557a23;}
page.task-full-view .fullInfo>.left,
page.task-full-view .fullInfo>.right {width: 50%;}
page.task-full-view .fullInfo>.left {padding-right: 15px;}
page.task-full-view .fullInfo>.left>.title,
page.task-full-view .fullInfo>.right>.title {width: 100%;margin: 0 0 14px;font-size: 22px;color: #557a23;}

page.task-full-view .fullInfo .info {display: flex;margin-bottom: 15px;}
page.task-full-view .fullInfo .info>.name,
page.task-full-view .fullInfo .info>.value {width: 50%;word-break: break-all;}
page.task-full-view .fullInfo .info>.value {color: #919DA8;}

page#formFullView .pointsTable tbody td.nonActive {color: rgba(44, 62, 80, 0.14);cursor: not-allowed;}
page#formFullView .pointsTable tbody td.nonActive label {cursor: not-allowed;}

page#formFullView main.scrollable {margin-top: 0;}

page#formFullView .templateName {margin-bottom: 15px;}

page#formFullView .binaryAnswer>label {display: flex;align-items: stretch;width: 100%;}
page#formFullView .binaryAnswer label:first-child {margin-bottom:  12px !important;}

page#formFullView .binaryAnswer>label>.inputWraper,
page#formFullView .binaryAnswer>label>.answerValue {width: 50%;}

page#formFullView .binaryAnswer>label>.inputWraper {position: relative;text-align: right;}
page#formFullView .binaryAnswer>label>.answerValue {text-align: left;}

page#formFullView .binaryAnswer>label>.inputWraper>input {position: absolute;top: 50%;right: 10px;transform: translateY(-50%);}
