@-webkit-keyframes ngdialog-fadeout {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes ngdialog-fadeout {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@-webkit-keyframes ngdialog-fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes ngdialog-fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.ngdialog,
.ngdialog *,
.ngdialog *:before,
.ngdialog *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.ngdialog {
  position: fixed;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  z-index: 10000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.ngdialog-overlay {
  position: fixed;
  background: rgba(0, 0, 0, 0.4);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-backface-visibility: hidden;
  -webkit-animation: ngdialog-fadein 0.5s;
  animation: ngdialog-fadein 0.5s;
}

.ngdialog.ngdialog-closing .ngdialog-overlay {
  -webkit-backface-visibility: hidden;
  -webkit-animation: ngdialog-fadeout 0.5s;
  animation: ngdialog-fadeout 0.5s;
}

.ngdialog-content {
  background: white;
  -webkit-backface-visibility: hidden;
  -webkit-animation: ngdialog-fadein 0.5s;
  animation: ngdialog-fadein 0.5s;
}

.ngdialog.ngdialog-closing .ngdialog-content {
  -webkit-backface-visibility: hidden;
  -webkit-animation: ngdialog-fadeout 0.5s;
  animation: ngdialog-fadeout 0.5s;
}

.ngdialog-close:before {
  font-family: 'Helvetica', Arial, sans-serif;
  content: '\00D7';
  cursor: pointer;
}

body.ngdialog-open {
  overflow: hidden;
}

.executors-tree {
    padding: 44px;
}

.executors-tree__header {
    font-family: Roboto;
    font-size: 34px;
    color: #f90;
    margin-bottom: 41px;
}

.executors-tree__body {
    display: flex;
    margin-bottom:10px;
}

.executors-tree__item noDirector{
  display:inline-block;
  color:red;
  font-weight:normal;
  margin-left:5px;
  cursor:help;
}

.executors-tree__col:first-child {
    width: 460px;
    margin-right: 52px;
}

.executors-tree__col:last-child {
    min-width: 200px;
    flex-grow: 1
}

.executors-tree__sub-header {
    margin-bottom: 15px;
    font-size: 20px;
    color: #4e6173;
}

.executors-tree__bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
}

.executors-tree__roles {
    margin-bottom: 45px;
}

.executors-tree__item {
    display: flex;
    padding: 8px 11px 7px;
    border: 1px solid #d8d8d8;
    border-radius: 2px;
    font-family: RobotoMedium, sans-serif;
    color: #4e6173;
    font-weight: 500;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12);
}

.executors-tree__item .md-checkbox {
    margin-right: 19px;
}

.executors-tree__selected-count {
    width: 20px;
    height: 20px;
    margin-left: auto;
    border-radius: 20px;
    font-family: RobotoMedium, sans-serif;
    font-size: 12px;
    color: #fff;
    text-align: center;
    background-color: #f90;
}

treecontrol li .tree-label {
    display: inline-block;
    width: calc(100% - 30px);
}

.executorsTree treecontrol li{
  margin-top: 2px!important;
}
.selectSomeEl{
  outline: 1px solid #696969!important;
  outline-offset: 0px!important;
}

.currUserAsExecutor {font-size: 16px;color: #4e6173;}
.currUserAsExecutor>span {display: inline-block;margin-left: 15px;}
.executorsTreeWrapper {position: relative;}
.executorsTreeOverlay {position: absolute;top: 0;left: 0;width: 100%;height: 100%;background-color: rgba(255, 255, 255, 0.8);z-index: 100;cursor: not-allowed;}
