
.header__profile-icon {
    cursor: pointer;
    display:inline-block;
    width: 31px;
    height: 31px;
    margin-right: 8px;
}

.header__profile-inf {
    display:inline-block;
    width: calc(100% - 55px);
}

.header__profile-inf
.header__profile-position,
.header__profile-name {
    display: inline-block;
}

.header__profile-name {
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.header-searching input {
    flex: 1;
    padding-left: 30px;
    border-width: 0;
    background-color: #f9f9f9;
}

.header-searching button {
    position: absolute;
    left: 5px;
    width: 19px;
    height: 19px;
    border-width: 0;
    background-color: transparent;
}

.header-searching button:active {
    cursor: default;
}

#logout {
    padding: 0;
    margin-left: auto;
    margin-right: 35px;
    border-width: 0;
    align-self: center;
    font-size: 16px;
    color: #f90;
    background-color: transparent;
    background-image: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}