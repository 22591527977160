.dialog-adding-item .ngdialog-content{
    width:550px !important;
}

.dialog-contractor .redStar:after {
    color: red;
    content: " *";
    position: relative;
    top: 6px;
    font-size: 24px;
}