.header__base {
    position: relative;
    z-index: 2;
    display: block;
    padding: 7px 10px;
    font-weight: bold;
}

.content {
    display: block;
    overflow-y: auto;
    max-height: 100%;
    padding: 10px;
    border: 1px solid lightgray;
    background-color: white;
}

.pointer {
    cursor: pointer;
}