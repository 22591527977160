/*Убить нахер весь файл*/



html, body {
    overflow: hidden;
}


ul, ol  {
    padding-left: 0;
}

#content {
    padding: 0;
}

#main {
    position: relative;
    height: 100%;
    min-height: 100%;
    margin-left: 230px;
    padding-bottom: 0px;
}

#main > ajaxloader {

    bottom: 0px;
    height: auto;
}

.section__header {
    position: relative;
}

.scrollable-content {
    height: 100%;
    background-color: #fff;
    overflow: auto;
}


.content-section {
    margin-bottom: 15px;
    background-color: #fff;
}

.content-section__header {
    margin-bottom: 20px;
}

.content-section__form-item {
    margin-bottom: 15px;
}

.content-section__form-item:last-child {
    margin-bottom: 0;
}

.svg {
    max-width: 100%;
    max-height: 100%;
}
