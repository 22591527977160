.md-shops-history {
    display: block;
    height: 100%;
}

.md-shops-history__container {
    height: 100%;
    min-height: 100%;
}

.md-shops-history__header {
    position: relative;
    z-index: 2;
    display: block;
    padding: 7px 10px;
    font-weight: bold;
}

.md-shops-history__filters {
    position: relative;
    z-index: 4;
    display: block;
    padding: 10px;
    border-right: 1px solid lightgray;
    border-left: 1px solid lightgray;
}

.md-shops-history__filter {
    display: inline-block;
    width: 200px;
    vertical-align: middle;
}

.md-shops-history__shops-filter {
    display: block;
    width: 408px;
    margin-top: 10px;
}

.md-shops-history__filters .ui-select-container {
    width: 100%;

}
.md-shops-history__filters .select2-choices {
    max-height: 350px;
    overflow-y: auto;
}
.md-shops-history__filters .select2-choice {
    border-color: lightgray !important;
}
.md-shops-history__filters .ui-select-container ul li.select2-search-field:not(.ng-touched) input{
    width: 100% !important;
}
.md-shops-history__filters .ui-select-container ul li.select2-search-field:not(.ng-touched){
    max-width: 100%;
    width: 100%
}

.md-shops-history .ui-select-container {
    width: 100%;
}

.md-shops-history content {
    position: relative;
    display: block;
    height: 100%;
    border: none;
}

.md-shops-history wrapper {
    display: block;
    overflow-y: auto;
    max-height: calc(100% - 110px);
    height: 100%;
    padding: 10px;
    border: 1px solid lightgray;
    background-color: white;
}

.full-screen {
    display: inline-block;
}

.full-screen input[type="checkbox"] {
    position: absolute;
    left: -9999px;
}

.full-screen-container {
    position: absolute;
    left: 0;
    z-index: 10000000;
    display: block;
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;

    background-color: #fff;
}

.full-screen-container.plannedChecklist {
    top: 0;
}

.full-screen-container.plannedChecklist__table {
    top: 137px;
    height: 100%;
    max-height: calc(100% - 137px);
}

.md-shops-history__container.full-screen-container {
    top: 0;
    max-height: 100%;
}

.full-screen-checker {
    padding: 8px;
    text-align: right;
    border-top: 1px solid lightgray;
    border-right: 1px solid lightgray;
    border-left: 1px solid lightgray;
}

.full-screen-checker > i {
    cursor: pointer;
}
