
/* Fix it after global style changes */
nav-tabs {
    display: block;
    background-color: #fff;
}

.tabs-nav {
    display: flex;
    padding-bottom: 0;
    padding-left: 0;
    width: 100%;
}

.tabs-nav li {
    position: relative;
    margin-right: 24px;
    margin-left: 24px;
    color: #34495e;
    text-transform: uppercase;
}

.tabs-nav li:first-child {
    margin-left: 0;
}

.tabs-nav li a {
    font-size: 13px;
    font-family: Opens Sans, sans-serif;
    font-weight: 700;
    color: #4e6173;
    text-decoration: none;
}

.tabs-nav li:after {
    position: absolute;
    bottom: -5px;
    left: 0;
    display: block;
    width: 0;
    height: 2px;
    background-color: #fe9102;
    content: "";

}

.tabs-nav .active:after {
    width: 100%;
}
