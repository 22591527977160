@import url('./mdIcons.css');
@import url('../lib/isteven-angular-multiselect/isteven-multi-select.css');
@import url('../lib/ng-image-gallery/dist/ng-image-gallery.min.css');
@import url('../lib/bootstrap/dist/css/bootstrap.min.css');
@import url('../lib/font-awesome/css/font-awesome.min.css');
@import url('./smartadmin-production.min.css');
@import url('./smartadmin-rtl.min.css');
@import url('./common.css');
@import url('./md.css');
@import url('./errorPage.css');
@import url('./override.css');
@import url('../lib/angular-tree-control/css/tree-control.css');
@import url('../js/libs/angular/select.min.css');
@import url('./ngDialog.css');
@import url('./ngDialog-theme-default.css');
@import url('./ngDialog-theme-plain.css');
@import url('./ngDialog-theme-mine.css');
@import url('./ngDialog-theme-progressive.css');
@import url('./selectize.default.css');
@import url('../lib/bootstrap-duallistbox/dist/bootstrap-duallistbox.css');
@import url('./main/typography.css');
@import url('./main/navbar.css');
@import url('./main/header.css');
@import url('./main/main.css');
@import url('./main/controls.css');
@import url('./main/buttons.css');
@import url('./main/nav-tabs.css');
@import url('./main/tasks.css');
@import url('./tasklist.css');
@import url('./contractors.css');
@import url('./md-shops-history.css');
@import url('./shop-dir-tasks.css');
@import url('./hout.css');
@import url('./demo.min.css');

@tailwind components;
@tailwind utilities;

*,::after,::before {
	--tw-translate-x: 0;
	--tw-translate-y: 0;
	--tw-rotate: 0;
	--tw-skew-x: 0;
	--tw-skew-y: 0;
	--tw-scale-x: 1;
	--tw-scale-y: 1;
	--tw-transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}
