/* tasks extend filter  */
tasks{
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    min-height: 0;
}

.taskCreation .ui-select-container {width: 100%;}

taskContent{display:block;width:calc(100% - 0px);}
taskContent showInfo{float:right;display:block;color:#4E6173;font-size:12px;cursor:pointer;margin-left:10px;}
taskContent showInfo:after{width:10px;height:5px;content:"";margin-left:4px;display:inline-block;position:relative;top:-1px;}
taskContent showInfo.up:after{background-image: url(../../img/up.png)}
taskContent showInfo.down:after{background-image: url(../../img/down.png)}


responseInfo{display:flex; flex-wrap: wrap;margin-bottom:10px;}
responseInfo>*{display:inline-block;width: 33%;min-width:180px;font-size:13px;color:#919DA8;}

taskSmallInfo{display:block;margin-bottom:10px;}
taskSmallInfo>*{display:inline-block;font-size:12px;color:#919DA8;margin-right:15px;}

@media screen and (max-width: 1300px) {
    responseInfo>*{width: 50%;}
}

.tasks-item__title h4{font-size:70%;}

 .task-item .tasks-item__comment textarea{
    background-color: #F6F7F8;
    padding:8px 10px 7px;
    font-size:14px;
    border:none;
    border-radius: none;
    width:100%;
    outline: none;
    min-height:35px;
 }

  .task-item .tasks-item__comment textarea.required{
    border: 1px solid rgba(255,0,0,.3);
  }

.task-item .tasks-item__comment textarea:disabled{
    opacity: .4;
    cursor: not-allowed
}

.tasks__filter-window {
    position: absolute;
    right:0px;
    top: 0px;
    z-index: 1;
    max-width: 535px;
    padding: 44px;
    background: #fff;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.13), 0px 1px 2px rgba(0, 0, 0, 0.2);
}

.tasks-filter {
    display: flex;
}

.tasks-filter__col:first-child {
    margin-right: 50px;
}

.tasks-filter__control {
    margin-bottom: 16px;
}

.tasks-filter__two-controls {
    display: flex;
}

.tasks-filter__two-controls .md-input:first-child {
    margin-right: 6px;
}

.tasks-filter__control label {
    color: #919da8;
}

.tasks-filter__btns .btn {
    min-width: 162px;
}

.tasks-filter__btns .btn:first-child {
    margin-right: 35px;
}

.tasks-filter__radio-group {
    display: flex;
}

.tasks-filter__radio-group .md-radio {
    margin-right: 10px;
}

/* tasks parent component */

.tasks__container {
  padding: 1px;
}

.tasks__pagination {
    display: flex;
    align-items: center;
}

.tasks__pagination > .btn {
    margin-left: 40px;
}

/* task component */
.task-item {
    display: block;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
    padding: 13px 16px 16px;
    margin-bottom: 5px;
}

.task-item--open .task-item__status {
    background-color: #66bb6a;
}

.task-item--open .task-item__main-inf-item--overdue-date {
    /*color: #66bb6a;*/
    color: #919DA8;
}

.task-item--open .task-item__main-inf-item--overdue-date .svg {
    /*fill: #66bb6a;*/
     fill: #919DA8;
}

.task-item--in-progress .task-item__status {
    background-color: #fe9102;
}

.task-item--in-progress .task-item__main-inf-item--overdue-date {
    color: #fe9102;
}

.task-item--in-progress .task-item__main-inf-item--overdue-date .svg {
    fill: #fe9102;
}

.task-item--closed .task-item__status {
    background-color: #607d8b;
}

.task-item--closed .task-item__main-inf-item--overdue-date {
    color: #607d8b;
}

.task-item--closed .task-item__main-inf-item--overdue-date .svg {
    fill: #607d8b;
}

.task-item--overdue .task-item__status {
    background-color: #f44336;
}

.task-item--overdue .task-item__main-inf-item--overdue-date {
    color: #f44336;
}

.task-item--overdue .task-item__main-inf-item--overdue-date .svg {
    fill: #f44336;
}

.task-item__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.task-item__status {
    display: inline-block;
    margin-left: -33px;
    padding: 5px 19px 6px 18px;
    border-top-right-radius: 92px;
    border-bottom-right-radius: 92px;
    font-family: 'RobotoMedium';
    font-size: 14px;
    color: #fff;
    line-height: 16px;
}

.task-new__form.taskTemplate button.templateCreate{margin-top:20px;}

.task-new__form.taskTemplate>.task-new__col{width: calc(100% - 55px);}

.task-item__id {
    margin-left: 12px;
    color: #919da8;
}

.task-item__role {
    color: #919da8;
}

.task-item__main-inf {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.task-item__main-inf--right {
    display: flex;
    flex-shrink: 0;
    justify-content: flex-end;
    align-self: flex-start;
    margin-top: 5px;
    margin-bottom: 5px;
}
.task-item__main-inf--right>*{
display: flex;
    align-items: center;
    color: #919da8;
    cursor:help;
}


.task-item__main-inf--right>*:not(:last-child) {
    margin-right: 13px;
}



.task-item__main-inf--right>* i.fa{
    font-size:130%;margin-right:3px;
}

.task-item__title{max-width: 100%;
    overflow: hidden;}

.task-item__title>*{vertical-align: middle}

.task-item__title h4{

    font-size: 24px;
    color: #4e6173;
    display:inline-block;

    text-overflow: ellipsis;
    max-width: 100%;
    overflow: hidden;
}
.task-item__title h4>small:after{content:":"}
.task-item__title h4>small{font-weight:80%;}

.task-item__title.task status {
  font-size: 14px;
  line-height: 14px;
  font-weight: bold;
}

.task-item__title status:before {
  content:"";
  display:inline-block;
  position: relative;
  top: -2px;
  width:6px;
  height:6px;
  border-radius: 100%;
  background-color: black;
  margin-right: 8px;
}

.task-item__title status{margin-left:10px;position: relative;bottom: -2px;}
.task-item__title status.open{color:#66BB6A;}
.task-item__title status.open:before{background-color:#66BB6A;}

.task-item__title status.in_progress{color:#FF9900;}
.task-item__title status.in_progress:before{background-color:#FF9900;}

.task-item__title status.in_progress.overdue{color:#F44336;}
.task-item__title status.in_progress.overdue:before{background-color: #F44336;}

.task-item__title status.closed{color:#607D8B;}
.task-item__title status.closed:before{background-color:#607D8B;}


.task-item__main-inf-item--atachments {
    position: relative;
    color: #4e6173;
}

.task-item__main-inf-item--atachments.hasAttachments {
    cursor: pointer;
    margin-right: 10px;
}
.task-item__main-inf-item--atachments.hasAttachments:after{
    position: absolute;
    top: 8px;
    right: -12px;
    border-width: 5px 5px 0;
    border-style: solid;
    border-color: #919da8 transparent transparent transparent;
    content: "";
    transition: all .5s linear;
}

.task-item__main-inf-item--atachments.hasAttachments.up:after{
    border-width: 0px 5px 5px;
    border-style: solid;
    border-color:  transparent transparent #919da8 transparent;
}

.task-item__main-inf-item .svg{
    height: 20px;
    margin-right: 6px;
    vertical-align: middle;
}

.task-item__main-inf-item--atachments span + .btn__show-more {
    margin-left: 7px;
}

.task-item__author-atachments {
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 10;
    max-width: 320px;
    max-height: 378px;
    border-radius: 2px;
    background-color: #fff;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
    overflow: auto;
}

.task-item__main-inf-item--closed-date {
    color: rgba(52, 73, 94, 0.54);
}

.task-item__main-inf-item--closed-date .svg {
    fill: rgba(52, 73, 94, 0.54);
}

.task-item__desc {
    margin-bottom: 16px;
    color: #919da8;
    text-overflow: ellipsis;
    max-width: 100%;
    overflow: hidden;
}

.task-item__simple-infs {
    display: flex;
    margin-bottom: 24px;
}

.task-item__simple-inf:not(:last-child) {
    margin-right: 16px;
}

.tasks-item__controls {
    display: flex;
    justify-content: space-between;
}

.tasks-item__controls--right .btn {
    min-width: 127px;
    font-size:12px;
    line-height:14px;
    padding:0 8px;
    height:28px;
}

.tasks-item__controls--right .btn:first-child {
    margin-right: 0px;
}

/* author attachments */
.task-attachment {
    margin-bottom: 0;
}

.task-attachment__item {
    padding: 7px 15px 8px 14px;
    cursor: pointer;
}

.task-attachment__inf {
    display: flex;
    align-items: center;
}

.task-attachment__item--active {
    cursor: default;
}

.task-attachment__item:hover {
    background-color: #e7ecee;
}

.task-attachment__icon {
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    margin-right: 13px;
}
li.task-attachment__item>.task-attachment__inf>.task-attachment__icon >i.fa {margin:0px auto;}
.task-attachment__name {
    font-size: 14px;
    color: #4e6173;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.task-attachment__file-inf {
    width: 215px;
    margin-right: 25px;
}

.task-attachment__other-inf {
    font-size: 12px;
    color: #919da8;
}

.task-attachment__controls {
    padding-top: 15px;
}

.task-attachment__controls li {
    display: flex;
    font-size: 12px;
    cursor: pointer;
}

.task-attachment__controls li:first-child {
    margin-bottom: 15px;
}

.task-attachment__control-icon {
    width: 13.5px;
    height: 13.5px;
    margin-right: 18px;
}

.task-attachment__control-icon .svg {
    fill: #919da8;
}

/* task new */

.task-new {
    height: calc(100% - 60px);


    overflow: auto;
}

.task-new__form h2>strong{color:orange;}

.task-new__form {
    display: flex;
    justify-content: space-between;
    padding-bottom: 200px;
}

.task-new__form h2{margin-bottom:5px;overflow: auto;}

.task-new__form .error{color:black;font-size:90%;font-style: italic;}

.task-new__col {
    width: calc(50% - 45px);
}

.task-new__main-inf .h2 {
    margin-bottom: 20px;
    font-size:21px;

}

.task-new__main-inf {
    margin-bottom: 30px;
}

.task-new__main-inf .md-input {
    margin-bottom: 20px;
}

.task-new__dates {
    display: flex;
    align-items: center;
}

.task-new__dates .md-input {
    max-width: 145px;
}

.task-new__dates-space {
    padding-right: 12px;
    padding-left: 12px;
}

.task-new__adding {
    position: relative;
}

.task-new__adding-container {
    max-height: 200px;
    overflow: auto;
}

.task-new__adding-header {
    position: sticky;
    top: 0;
}

.task-new__adding-header h2 {
    margin-bottom: 0 !important;
    padding-bottom: 10px;
    background-color: #FFFFFF;
}

.task-new__adding:not(:last-child) {
    margin-bottom: 50px;
}

.task-new__adding .h2 {
    margin-bottom: 20px;
}

.task-new__adding .plus-btn {
    position: absolute;
    top: 0;
    right: 0;
}

.task-new__adding-inf {
    color: #919da8;
}

.task-new__adding-inf-equipment {
    max-height: 300px;
    overflow: auto;
}

.task-new__adding-inf-equipment-error_link {
    text-decoration: underline;
    cursor: pointer;
}

.task-new__adding .shopsSelection { display: flex; }
.task-new__adding .shopsSelect { flex-grow: 1; }

.task-new__type {
    margin-bottom: 30px;
}

.task-new__type-label {
    margin-bottom: 12px;
    color: #4e6173;
}

.task-new__checkbox {
    display: flex;
    align-items: center;
    padding-bottom: 26px;
}

.task-new__checkbox checkbox {
    margin-right: 20px;
}

.task-new__attachments-count {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}

.task-new__notifications .select--gray {
    max-width: 200px;
}

.task-new__attachments-count number-input {
    padding-left: 50px;
}

.task-new__form-control {
    text-align: right;
}

.task-new__periodic {
    margin-bottom: 50px;
}

.task-new__periodic-header {
    margin-bottom: 12px;
}

.task-new__periodic .h2 {
    margin-bottom: 26px;
}

.task-new__periodic-types {
    margin-bottom: 20px;
}

.task-new__periodic-types .md-radio {
    margin-right: 20px;
}

.task-new__week-days {
    display: flex;
}

.task-new__periodic-options {
    margin-bottom: 34px;
}

.task-new__days-to-complete {
    display: flex;
    margin-bottom: 30px;
    color: #4e6173;
}

.task-new__week-days .task-new__week-day{
    margin-right: 10px
}

.task-new__week-day {
    position: relative;
    z-index: 1;
    width: 32px;
    height: 32px;
    border-radius: 32px;
    font-size: 12px;
    color: #919da8;
    text-align: center;
    line-height: 32px;
    background-color: #f9f9f9;
    transition: color .35s linear;
    will-change: transform;
    cursor: pointer;
}

.task-new__week-day:after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    border-radius: 32px;
    transform: scale(0);
    transition: transform .25s linear, background-color .25s linear;
    content: '';
}

.task-new__week-day.active {
    font-family: RobotoMedium, sans-serif;
    color: #fff;
}

.task-new__week-day.active:after {
    background-color: #f90;
    transform: scale(1);
}

.task-new__month-days {
    display: flex;
    flex-wrap: wrap;
    max-width: 330px;
}

.task-new__month-day {
    position: relative;
    z-index: 1;
    flex-shrink: 0;
    width: 38px;
    height: 38px;
    border-radius: 38px;
    font-family: RobotoMedium, sans-serif;
    font-size: 12px;
    color: #919da8;
    text-align: center;
    line-height: 38px;
    background-color: #fff;
    will-change: transform;
    cursor: pointer;
}

.task-new__month-day:after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    border-radius: 38px;
    transform: scale(0);
    transition: transform .25s linear, background-image .25s linear;
    content: '';
}

.task-new__month-day.active:after {
    background-image: linear-gradient(0deg, #ffb84d 0%, #f90 100%);
    transform: scale(1);
}

.task-new__month-day.active {
    color: #fff;
    cursor: default;
}

.task-new__month-day:not(:nth-child(7n)) {
    margin-right: 10px;
}

.task-item .btn--without-border {
    padding-left: 0;
}

.tasks-item__executor-attachments-count {
    color: #f90;
}

.tasks-item__comment {
    margin-bottom: 10px;
}

.tasks-item__comment .svg{
    display: inline-block;
    max-width: 18px;
    margin-right: 10px;
    vertical-align: middle;
    fill: rgba(52, 73, 94, 0.54);
}

.tasks-item__video-monitoring-answer {
    margin-bottom: 10px;
    color: #4e6173;
}

/* tasks-default-filter.noCreateBtn {width: calc(100% - 180px);} */
#taskFull tasks-default-filter {width:100%;}

button.addTask{margin-left:30px;}

.paginationChooser{margin:0px 5px;}

pokazometr{display:block;margin-bottom:20px;}
pokazometr>line{margin-top:5px;display:block;height:8px;border-radius:10px;
  background: gray;
}
pokazometr>line>div{height:100%;}
pokazometr>line>div>fill{border-radius:10px;width:0%;display:block;transition: width 2s ease-in-out;}
pokazometr>line>div>fill.filled{height:100%;width:100%;}

.task-item processStatus{display: block;margin-bottom:20px;}
.task-item processStatus p{padding:0px;margin:0px;}

.task-item userRole{
    line-height: 20px;
    display: flex;
    align-items: center;
    margin-left:5px;
    margin-right: 15px;
    color: gray;
    font-size: 90%;
}

commentFromResponseOnTask{display:block;margin-bottom:10px;}
commentFromResponseOnTask>svg{width:15px;height:15px;margin-right:7px;}

taskFullInfo{display:block;position: relative;}
taskFullInfo>left{width:50%;float:left;}
taskFullInfo>right{width:50%;}
taskFullInfo table{width:100%;}
taskFullInfo>*>table:not(:visible:last-of-type){margin-bottom:25px;}
taskFullInfo table>caption{font-size:24px;margin-bottom:1px;padding:0px;}
taskFullInfo table tr>td{padding-bottom:6px;}
taskFullInfo table tr>td:first-of-type{color:#4E6173; vertical-align: initial; padding-right: 5px;}
taskFullInfo table tr>td:first-of-type:after{content:":";}
taskFullInfo .linkedTaskWrapper {width: 100%;margin-top: 15px;}


taskFullInfo table.unk, taskFullInfo table *.unk{color:red;display:none;}

form.filters{margin-top:6px;}

filter.forTwo>input{border:1px solid red;}

page#taskFull left,page#taskFull right{max-height: 0px;transition: all 0.3s ease-in-out;overflow:hidden;}
page#taskFull left.visible,page#taskFull right.visible{max-height: 500px;}
page#taskFull left{max-height: 30px;}

page#taskFull showFull{background-position: center center;background-repeat:no-repeat;transition: all 0.5s ease-in-out;display:block;position: absolute;right:15px;top:15px;width: 40px; height:30px;cursor:pointer;}
page#taskFull showFull.up{background-image:  url(../../img/up.png)}
page#taskFull showFull.down{background-image:  url(../../img/down.png)}


page#taskFull overdue{display:inline;color:red;font-style: italic;}
page#taskFull overdue:before{content:"(";}
page#taskFull overdue:after{content:")";}

page#taskFull .tasks__filter .tasks__filter--left{display:none;}
page#taskFull .tasks__filter .tasks__filter--right showExtended{display: none}
.tasks__filter responsesActions{display:none;}
page#taskFull .tasks__filter responsesActions{display:block;}

page#taskFull .tasks__filter{margin-bottom:10px;}



responseAttachments{display:block;}
responseAttachments>item{margin-top:5px;position: relative;max-width: 200px;text-overflow: ellipsis;margin-bottom:5px;display:inline-block;cursor:pointer;padding-right:5px;vertical-align: top;margin-right:20px;}
responseAttachments>item:hover{text-shadow: 1px 1px 1px rgba(0,0,0,.3);}
responseAttachments>item i{margin-right: 10px;}
responseAttachments>item p{overflow: hidden;font-size:90%;width:110px;}
responseAttachments>item img{height:60px;}
responseAttachments>item i{font-size:40px !important;}
responseAttachments>item i.fa.fa-times {position: absolute;right: -18px;top: -10px;font-size:15px !important;}
responseAttachments>item size:after{content:" ·";}
responseAttachments>item size,responseAttachments>item date{white-space: nowrap;font-size:80%;font-style: italic;}
responseAttachments>item td{vertical-align: top;}
responseAttachments>error{color: black;display:block;margin-bottom:5px;}

div.routedFilters{margin-right: 24px;}
div.routedFilters>*{display:inline-block;margin-right:8px;}
div.settedChecklist{width:auto;margin-left:4px;position:relative;}
div.settedChecklist>p{font-size:110%;}
div.settedChecklist>close{display:block;position:absolute;right:-21px;top:50%;transform: translateY(-50%);font-size: 18px;cursor:pointer;}


.taskCreation .task-new__checkbox.forSelf{margin-top:10px;}
.taskCreation .select2-container-multi .select2-choices{min-height:37px;}

.task-item .linked-task-wrapper {margin-bottom: 10px;}

.task-item__main-inf-item_loaded-date_title {font-size: 90%; margin-left: 5px;}
.task-new__main-inf-time { align-self: flex-start; display: flex;justify-content: space-between;align-items: center;flex-grow: 1; margin-left: 20px; }
.task-new__main-inf-time-input { width: 60px; }
.task-new__main-inf-time-input-utc { display: flex;align-items: center;margin-top: 15px; }
.task-new__main-inf-time-input-utc_checkbox { display: block;margin-right: 5px; margin-left: 15px; }
.task-new__main-inf-time-input-utc--disabled,
.task-new__main-inf-time-input-utc--disabled .task-new__main-inf-time-input-utc_checkbox { opacity: 0.5 }
.task-new-urgent_checkbox {vertical-align: bottom;margin-right: 8px}
.task-item-urgent {border: 1px solid #F2C94C;}

.ngdialog {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 !important;
}

.task-modal .ngdialog-close:before {
    top: 39px !important;
    right: 34px !important;
    font-size: 50px !important;
}

.goods-selection {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    max-height: 100vh;
    padding: 44px;
}

.goods-selection_title {
    margin-bottom: 41px;
    font-size: 34px;
    font-family: Roboto;
    color: #557a23;
}

.goods-selection_sub-title {
    margin-bottom: 15px;
    font-size: 20px;
    color: #4e6173;
}

.goods-selection_tabs {
    flex-shrink: 0;
}

.goods-selection_search-container {
    flex-shrink: 0;
    margin-top: 40px;
    min-height: 20vh;
}

.goods-selection_textarea-wrapper {
    text-align: right;
}

.goods-selection_textarea-wrapper textarea {
    min-height: 20vh;
}

.goods-selection_search {
    margin-bottom: 15px;
}

.goods-selection_no-search-result {
    font-size: 16px;
    text-align: center;
}

.goods-selection_list {
    height: 30vh;
    margin-bottom: 15px;
    overflow: auto;
}

.goods-selection_selected-list-item,
.goods-selection_list table.styled:not(.nohover) tbody tr.goods-selection_selected-list-item:hover {
    background: rgba(52, 122, 35, 0.3) !important;
}

.goods-selection_pagination-wrapper {
    display: flex;
    justify-content: flex-end;
}

.goods-selection_selected-title {
    margin-top: 40px;
}

.goods-selection_selected-list {
    overflow: auto;
}

.goods-selection_selected-list table.styled th:nth-child(2) {
    border-right: none;
}

.goods-selection_selected-list table.styled th:nth-child(3) {
    border-left: none;
}

.goods-selection_footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
}

.task-types-filter-container dropdown-filter.visible > content {
    width: 50vw !important;
    min-width: 460px;
}

.task-types-filter-container .btn__checkbox {
    margin-right: 10px;
    margin-bottom: 10px !important;
}

.task-types-filter-container .input-group-btn {
    width: auto;
    margin-left: 10px;
}

form.filters.task-types-filter-container {
    margin: 0 24px 0 0;
}

.task-types-filter-container dropdown-filter {
    width: auto;
    margin-bottom: 0;
}

#tasks header .addBtn {
    margin-left: 16px;
    vertical-align: middle;
}

#tasks tasks-default-filter {
    flex: 1;
}

.task-types-filter {
    width: 100%;
}

.task-types-filter #ui-select-choices-0 {
    display: flex;
    flex-wrap: wrap;
}