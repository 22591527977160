#left-panel {
    height: 100%;
    width: 230px;
    padding-top: 0px;
    background-color: #e7e9ec;
    background-image: none;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.25);
    float:left;
    position:relative; /*иначе менюшка подъезжает под контент*/
    z-index:1000;
}

nav ul {
    margin-top: 2px;
}

nav > ul > li > a b {
  position: absolute;
  top:3px;
  right: 10px;
}

nav>ul>li {
  width: 100%;
  margin-bottom: 3px;
}



nav>ul>li:last-child {
    margin-bottom: 0;
}

nav ul li a {
  position: relative;
  display: block;
  font-family: RobotoMedium, sans-serif;
  padding:4px 0px  4px 6px;
  color: #4e6173;
  line-height: 24px;
}

nav ul li a:hover {text-decoration: none;}

nav ul li a:focus{color: #4e6173;}

.minified nav ul > li > a{padding: 4px 0px  4px 10px;}
nav ul ul {
    margin: 0;
    padding: 0;
    background-color: #b2bac2;
}

nav.test>ul>li>a {
    text-transform: uppercase;
}

nav ul li a:active,
nav ul ul li > a:hover,
nav ul li.open > a,
nav ul li a:hover,
nav ul .active > a {

    color: #fff;
    background-color: #b2bac2;
}

/*nav > ul > li > a b {
    display: none;
}*/



nav > ul > li > a > i {
    width: 24px;
    height: 24px;
    line-height: 24px;
    border: 1px solid #4e6173;
    border-radius: 24px;
    margin-right: 5px;
    font-size: 12px!important;
    vertical-align: middle;
    text-align: center;
}

nav > ul > li > a > i:before {
    line-height: 22px;
}

nav > ul ul li a i {
    margin-right: 7.5px;
}

nav > ul > li > ul > li > a,
nav > ul > li > ul > li > ul > li > a {
    padding: 3px 0px 3px 20px;

}

ul > li > a > i{
    color: #4e6173;
}

nav ul li a:active i,
nav ul ul li > a:hover i,
nav ul li.open > a i,
nav ul li a:hover i,
nav ul .active > a i {
    color: #fff;
    border-color: #fff;
}


nav > ul > li > ul::before,
nav > ul ul li::before {
    display: none;
}

.minified nav > ul > li {
    border-bottom-width: 0;
    border-top-width: 0;
}

.minified nav > ul > li > ul {
    min-height: auto;
    background-color: #fff;
}

.minified nav > ul > li > ul > li {
    background-color: #fff;
}

.minified nav > ul > li > a > span {
    display: none;
    padding-bottom: 4px;
}

nav > ul > li:not(.open) ul {display: none;}

.minifyme {
  border-bottom: 1px solid #302F2F;
  background: #454545;
  color: #A8A8A8;
  text-align: center;
  padding: 0;
  width: 36px;
  height: 28px;
  display: block;
  position: absolute;
  right: -3px;
  border-radius: 5px 0 0 5px;
  cursor: pointer;
  margin-top: 5px;
  font-size: 19px;
  transition: all .1s linear 0s;
}

.minifyme:hover {
  background: #646464;
  color: #E4E4E4;
  right: 0;
}

.minified #left-panel, .minified nav>ul>li {
  overflow: visible;
}

.minified #left-panel {
  width: 45px
}

.minified nav > ul > ul {
  min-height: 100px !important;
  max-height: 180px !important;
  overflow-y: scroll;
  overflow-x: hidden;
  background: #ed1c24 !important
}

.minified nav>ul>li:hover>a>.menu-item-parent, .minified nav>ul>li:hover>ul {
  display: block!important;
}
