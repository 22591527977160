h1, h2, h3, h4 {
    font-family: Arial, sans-serif;
}
.tasklist .actionTitle{
    padding: 20px 0 10px 0;
    border-bottom: 1px dotted #e9e9e9;
    display: flex;
    align-items: center;
}
.tasklist .btnCenter, .newTask .btnCenter{
    display: flex;
    align-items: center;
}
.tasklist header i{
    vertical-align: middle;
}
.btnCreateTask, .btnReturnTask, .btnNotifyTask, .btnExcelTask{
    margin-right: 20px;
    width: 146px;
}

.btnReturnTask, .titleButtons, .btnExcelTask{
    margin-left: auto;
}

.tasklist .btnSearch{
    height: 30px;
}
.tasklist .btnReset{
    height: 30px;
    margin-right: 10px;
}
.btnTaskInBody{
    width: 146px;
    margin-bottom: 20px;
}
.tasklist .btnBlue{
    border-color: #285e8e !important;
    background-image: linear-gradient(to top, #3071a9,#285e8e);
    width: 186px;
}
.tasklist .btnBlue:hover{
    background-color:#285e8e !important;
    border-color:#285e8e !important;
}
.tasklist .btnTaskDefault, .tasklist .btnTaskDefaultInTab, .newTask .btnTaskDefault{
    width: 116px;
}
.tasklist .btnTaskDefault,.newTask .btnTaskDefault{
    margin-bottom: 20px;
}
.tasklist .btnForm{
    width: 146px;
    margin: 10px auto;
}
.tasklist .actionTabs .actionItem>a {
    height: 23px;
    padding: 3px 28px;
    display: inline-block;
}
.tasklist .actionTabs .actionItem>a>i {
   color: #555 !important;
   margin-right: 5px;
}
.tasklist .actionTabs .actionItem .badge{
    background-color: #f5756f;
    opacity: 1;
}
.tasklist .actionTags{
    padding: 5px 0 15px;
}
.tasklist .actionTags label{
    border-radius: 15px;
    padding: 3px 22px 2px;
    font-weight: 700;
    font-size: 13px;
    background-color: #fff;
}
.tasklist .actionTags .titleFilters{
    padding-right: 20px;
}
.tasklist .actionTags label.btn-outline-new{
    border: 1px solid #b1b1b1;
    color:#b1b1b1;
    margin: 0 5px 0 0;
}
.tasklist .actionTags label.btn-outline-new.active{
    background-color: #b1b1b1;
    color:#fff;
}
.tasklist .actionTags label.btn-outline-in{
    border: 1px solid #9dd300;
    color: #9dd300;
    margin: 0 5px
}
.tasklist .actionTags label.btn-outline-in.active{
    background-color: #9dd300;
    color:#fff;
}
.tasklist .actionTags label.btn-outline-completed{
    border: 1px solid #57c53e;
    color: #57c53e;
    margin: 0 5px
}
.tasklist .actionTags label.btn-outline-completed.active{
    background-color: #57c53e;
    color:#fff;
}
.tasklist .actionTags label.btn-outline-overdue{
    border: 1px solid #f5756f;
    margin: 0 0 0 5px;
    color: #f5756f;
}
.tasklist .actionTags label.btn-outline-overdue.active{
    background-color: #f5756f;
    color:#fff;
}
.tasklist .actionTags.leftTags{
    display: inline-block;
}
.tasklist .actionsTable{
    overflow-x: auto;
    margin-bottom: 20px;
}
.tasklist .actionsTable .actionsHead{
    height:48px;
    font-size: 14px;
    vertical-align: top;
}
.tasklist .actionsTable .actionsHead td{
    white-space: nowrap;
    text-align: center;
}
.tasklist .pointer{
    cursor: pointer;
}
.tasklist .leftBtn{
    margin-right: 18px;
}
.newTask .leftBtn{
    margin-right: 18px;
}
.newTask .formBtn{
    margin-bottom: 0;
}
.newTask .dropdownInput{
    display: flex;
    align-items: center;
}
.newTask .dropdownInput label{
    font-weight: bold;
    width:180px;
}
.tasklist .signatureOnBtn, .newTask .signatureOnBtn{
    padding-left: 31px;
    font-size: 11px;
    padding-top: 2px;
}
.tasklist .signatureOnBtnInTd, .newTask .signatureOnBtnInTd{
    font-size: 11px;
    padding-top: 2px;
    /*position: absolute;*/
    /*left:0;*/
    /*right:0*/
}
.tasklist .actionsTable .actionsHead td.active{
    background-color:  #f0f0f0;
}
.tasklist .actionsTable td{
    text-align: left;
}
.tasklist .actionsTable td.tdBtn{
    text-align: center;
    /*position: relative;*/
}
.tasklist .actionsTable .taskRow.active{
    background-color: #e9f1e2;
}
.tasklist .actionsTable .taskRow .iconField{
    text-align: center;
    color: #0E6724;
}
.tasklist .taskFiltersContainer{
    text-align: left;
}
.tasklist .taskFiltersContainerInTab, .tasklist .taskFiltersContainer{
    margin: 10px;
}
.tasklist .taskFiltersContainer fieldset{
    margin-right: 20px;
}
.tasklist .taskFiltersContainer button{
    margin-bottom: 0;
}
.tasklist .searchBlock{
    display: inline-block;
    margin: 10px 0 ;
}
.tasklist .searchBlock button {
    width: 98px;
}
.tasklist fieldset.taskFilter, .tasklist fieldset.taskFilterDouble {
    border: 1px dashed gray !important;
    padding: 0 10px 10px 10px;
    display: inline;
}

.tasklist fieldset.taskFilter {
    width: 204px;
}

.tasklist fieldset.taskType{
    width: 204px;
}
.tasklist fieldset.taskType.intersection {
    width: 222px;
}
.tasklist fieldset.taskType select {
    width: 180px;
}
.tasklist fieldset.taskType.intersection select {
    width: 202px;
}

.tasklist fieldset.taskFilterGrouped .radio-inline{
    padding-left: 0;
}
.newTask .itemRow{
    padding: 10px 0;
    display: flex;
    align-items: center;
}
.newTask .itemRow .radio-inline{
    padding-left: 0;
}
.tasklist fieldset.taskFilterGrouped .radio-inline input[type=radio], .newTask .itemRow .radio-inline input[type=radio]{
    position: relative;
}
.tasklist fieldset.taskFilterGrouped .radio-inline span, .newTask .itemRow .radio-inline span{
    padding-left: 3px;
}
.tasklist fieldset.taskFilterInTab{
    width: 200px;
    vertical-align: top;
    display: inline-block;
    border: 1px dashed gray;
    position: relative;
    margin-right: 5px;
}
.tasklist .select2-container{
    width: 100%;
}
.tasklist .select2-choices li{
    max-width: 200px;
}
.tasklist fieldset.taskFilterInTab{
    margin-right: 20px;
}
.tasklist fieldset.taskFilter input{
    width: 180px !important;
    font-size: 14px;
}
.tasklist fieldset.taskFilterDouble input{
    width: 87px !important;
    height: 22px;
}
.tasklist fieldset.taskFilterGrouped input{
    width: auto !important;
}
.tasklist fieldset ul.select2-choices{
    min-height: 20px !important;
}
.tasklist fieldset ul.select2-choices li{
    min-height: 20px !important;
}
.tasklist fieldset ul.select2-choices li input{
    margin: 0 !important;
    padding: 0 0 0 5px !important;
    font-size: 14px !important;
    box-shadow: inset 0 1px 0 #d6d6d6 !important;
}
.tasklist .select2-drop-active{
    border: 1px solid #5D98CC !important;
    border-top: none;
    border-bottom-width: 3px !important;
}
.tasklist .select2-container-multi.select2-container-active .select2-choices{
    border: 1px solid #5D98CC !important;
    outline: 0;
}
.tasklist .select2-results .select2-result-label{
    font-size: 85%;
}
.tasklist .select2-results .select2-highlighted{
    background-color: #3276b1;
}
.tasklist .select2-container-multi .select2-choices .select2-search-choice{
    background-color: #3276b1;
    border-color: #2a6395;
}
.tasklist .select2-container-multi .select2-choices .select2-search-choice{
    line-height: 12px;
    font-size: 85%;
    margin: 2px;
    padding: 1px 18px 1px 4px;
}
.tasklist .select2-container-multi .select2-search-choice-close{
    font-size: 10px;
    min-height: 10px;
    min-width: 10px;
}
.tasklist fieldset.taskFilterDouble{
    width: 200px;
}
.tasklist fieldset.taskFilterDouble {
    width: 204px;
}
.tasklist fieldset.taskFilterDoubleInTab{
    margin-right: 20px;
}
.tasklist legend.taskFilter {
    width:auto;
    padding:0 2px;
    border-bottom:none;
    font-size: 14px;
    margin-bottom: 0px;
}
.tasklist .taskInfo .infoItems{
    padding: 20px 0 0 10px;
}

.tasklist .datepickerTopLeft ul.dropdown-menu{
    top: -282px !important;
}

.tasklist .taskInfo .required:after{
    content:" (обязательно к заполнению)";
    color: red;
}
.tasklist .taskInfo .infoItems .infoItem label{
    font-weight: bold;
    /*line-height: normal;*/
    height:39px;
    cursor: default;
    /*margin: 3px 0;*/
}

.tasklist .taskInfo .infoItems .infoItem{
    padding-bottom: 10px;
    /*line-height: 39px;*/
}
.tasklist .taskInfo .infoItems .infoItem .inputTaskCalendar{
    height: 34px;
    width: 100px;
}
.tasklist .taskInfo .infoItems .infoItem .inputTaskCalendarBtn{
    padding-left: 0;
    height: 34px;
}
.tasklist .dropdownPanel{
    cursor: pointer;
    font-size: 13px;
    /*border-bottom: 1px dotted #e9e9e9;*/
}
.tasklist .dropdownPanel .dropdownPanelLabel{
    padding-left: 8px;
}
.tasklist .panel-default{
    margin-bottom: 5px;
}
.newTask .itemLabel{
    font-weight: bold;
    /*padding-bottom: 20px;*/
    cursor: default;
}
.newTask .itemRow .inputTask{
    height: 34px;
    width: 100%;
    padding-left: 15px;
}
.newTask .itemRow .inputTaskCalendar{
    height: 34px;
}
.newTask .itemRow .inputTaskCalendarBtn{
    width:78px;
    padding-left: 0;
}
.newTask .btnTaskOpenModal {
    padding: 6px 25px 5px;
}
.newTask .taskType{
    width:215px;
    margin-left: 15px
}
    /*progressive*/
.btnProgressive{
    height: 31px;
    margin: 10px 0 0 5px;
    padding: 0 22px;
    cursor: pointer;
}

.newTask .attachmentCounter{
    text-align: center;
    width: 50px;
    border: 1px solid #ccc;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075)!important;
}
.newTask .attachmentCounterGroup .decrementCounter{
    border-radius: 4px;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
}
.newTask .attachmentCounterGroup .incrementCounter{
    border-radius: 4px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}
.newTask .attachmentCounterGroup{
    width: 113px;
    margin-left: 0px;
    padding-left: 15px;
}

.attachmentCounter::-webkit-inner-spin-button {
    display: none;
}
.attachmentCounter{
    -moz-appearance:textfield;
}
span.showFull{
    position:relative;
}
span.showFull button{
    padding:0px 5px;
}
span.showFull button i{
    margin-right:0px;
}
.tasklist .numberFieldVal input[type='number'] {
    -moz-appearance:textfield;
}
.tasklist .numberFieldVal input::-webkit-outer-spin-button,
.tasklist .numberFieldVal input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

