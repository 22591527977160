::-webkit-input-placeholder {
    color: #919da8;
}

::-moz-placeholder {
    color: #919da8;
}

:-ms-input-placeholder {
    color: #919da8;
}

/* app inputs */
input[type='text'],
input[type='number'],
select,
form.filters input[type='text'],
form.filters input[type='number'],
form.filters select,
.app-input {
	width: 100%;
	height: 34px;
	padding-left: 9px;
	border: 1px solid transparent;
	border-radius: 2px;
	background-color: #F6F7F8;
}

controls input[type='text']:focus,
controls input[type='number']:focus,
form.filters input[type='text']:focus,
form.filters input[type='number']:focus,
form.filters select:focus,
.app-input:focus {
	border: 1px solid #FF9900;
}

controls input[type='checkbox'],
form.filters input[type='checkbox'],
.app-checkbox {
  -webkit-appearance: none;
  box-sizing: border-box;
	text-align: center;
	border-radius: 2px;
	background-color: #f9f9f9;
  border: 1px solid #bebebe;
  height: 18px;
  width: 18px;

  /*  FontAwesome for checkmark */
  font: normal normal normal 14px/1 FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

controls input[type='checkbox']:checked,
form.filters input[type='checkbox']:checked,
.app-checkbox:checked {
  background-color: #FF9900;
  border: 1px solid #FF9900;
}

controls input[type='checkbox']:after,
form.filters input[type='checkbox']:after,
.app-checkbox:after {
  content: "\f00c";
  position: relative;
  display: inline-block;
  color: #f9f9f9;
  font-size: 12px;
  height: 100%;
  top: -1px;
}

controls input[type='checkbox']:checked:after,
form.filters input[type='checkbox']:checked:after,
.app-checkbox:checked:after {
  color: white;
}

controls input[type='radio'],
form.filters input[type='radio'],
.app-radio {
  -webkit-appearance: none;
  position: relative;
  width: 18px;
  height: 18px;
  border-radius: 100%;
  box-sizing: border-box;
  border: 1px solid rgba(44, 62, 80, 0.54);
  background: white;
}

controls input[type='radio']:checked,
form.filters input[type='radio']:checked,
.app-radio:checked {
  border: 1px solid #FF9900;
}

controls input[type='radio']:after,
form.filters input[type='radio']:after,
.app-radio:after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background: white;
  border-radius: 100%;
  transform: translate(-50%, -50%);
}

controls input[type='radio']:checked:after,
form.filters input[type='radio']:checked:after,
.app-radio:checked:after {
  background-color: #FF9900;
}

controls input[type='radio'][disabled],
form.filters input[type='radio'][disabled],
.app-radio[disabled] {
  border: 1px solid rgba(44, 62, 80, 0.14);
  cursor: not-allowed;
}


.app-input.with-border {
  border: 1px solid #bebebe;
}

.app-btn {
  display: inline-block;
  height: 28px;
  padding: 0 9px;
  background-color: #FF9900;
  color: white;
  border: none;
  border-radius: 4px;
}

.app-btn.grey {
  background-color: #E7E9EC;
  color: #4E6173;
}

.app-btn.wide {
  padding-left: 18px;
  padding-right: 18px;
}

.app-btn.round {
  border-radius: 100%;
}

.app-btn-group {
  display: inline-flex;
  height: 24px;
  line-height: normal;
}

.app-btn-group button {
  height: 24px;
  color: white;
  border: 1px solid #FF9900;
  border-left: none;
  border-right: none;
  background-color: #FF9900;
}

.app-btn-group button:hover {
  background-color: rgba(255, 153, 0, 0.8);
}

.app-btn-group button:active {
  background-color: #e68a00;
  box-shadow: inset 0 0px 6px 0 rgba(99, 99, 99, 0.5)
}

.app-btn-group button.selected {
  background-color: #e68a00;
  box-shadow: inset 0 0px 3px 0 rgba(99, 99, 99, 0.5)
}

.app-btn-group button:first-child {
  border-left: 1px solid #FF9900;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.app-btn-group button:last-child {
  border-right: 1px solid #FF9900;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

search {display: inline-block;position: relative;min-width: 24px;min-height: 24px;}
search>input {display: inline-block;height: 28px;width: 0;border-radius: 100px !important;background-color: #F6F7F8;border: none;transition: width 0.3s ease-in-out;}
search>button {background: transparent;border: none;width: 24px;height: 24px;font-size: 16px;padding: 0;text-align: center;}
search.expanded>button.search-btn {position: absolute;top: 50%;left: 10px;transform: translateY(-50%);}
search.expanded>button.clear-btn {position: absolute;right: 10px;top: 50%;transform: translateY(-50%);}
search.expanded>input {width: 250px;padding-left: 40px;}

search.primary-btn {
    width: 100%;
}
search.primary-btn>input {
    border: 1px solid #d8d8d8;
    padding-right: 45px;
}
search.primary-btn>button {
    position: absolute;
    top: 0;
    right: 0;
    box-sizing: border-box;
    width: 45px;
    height: 100%;
    border: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
    text-align: center;
    background: #557a23;
}
search.primary-btn>button i {
    color: white;
}

toggle {display: inline-block;}
toggle:hover {cursor: pointer;}
toggle:after {content: '';display: inline-block;width: 10px;height: 5px;margin-left: 10px;transform: translateY(-40%);background: url('../../img/down.png') no-repeat;}
toggle.visible:after {background-image: url('../../img/up.png');}

/* input wrap */

.md-input-wrap-with-button {
    position: relative;
}

.md-input-wrap-with-button>select {
  padding-right: 25px;
}

.md-input-wrap-with-button .btn__with-icon {
  position: absolute;
  width: 28px;
  top: 0;
  right: 1px;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  background: #F6F7F8;
}

.md-input-wrap-with-button .btn__with-icon>i {
  color: #C9D0D6;
  font-size: 18px;
}

/* input wrap */

.md-input {
    width: 100%;
    height: 32px;
    padding: 7px 16px 6px;
    border: solid 1px transparent;
    border-radius: 2px;
}

.md-input--orange {
    border-color: #f90;
    border-radius: 1px;
    background-color: #fff;
}

.md-input--orange.ui-select-container{
    min-width:250px;
    border:1px solid #f90;
}

.md-input--white {
    padding: 8px 25px 7px 8px;
    border-color: #e5e5e5;
    font-size: 12px;
    color: #4e6173;
}

.md-input--no-border {
    height: 39px;
    padding: 12px 16px;
    border: none;
    background-color: #f9f9f9;
    transition: border .25s linear;
}

.md-input--with-btn {
    padding-right: 40px;
}

.md-input--font-20 {
    height: 48px;
    font-size: 20px;
}

.md-input--no-border:hover,
.md-input--no-border:focus {
    border-color: #f90;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.input-with-icon {
    position: relative;
}

.input-with-icon__icon {
    position: absolute;
    top: 10px;
    left: 18px;
    width: 20px;
}

.input-with-icon .md-input {
    padding-left: 48px;
}

.md-textarea {
    min-height: 93px;
    resize: none;
}

/* checkbox */

.md-checkbox {
    position: relative;
    display: inline-block;
}

.md-checkbox input[type='checkbox'] {
    position: absolute;
    left: 0;
    z-index: 10;
    opacity: 0;
}

.md-checkbox input[type='checkbox']:not(:checked) + .md-checkbox__field,
.md-checkbox input[type='checkbox']:checked + .md-checkbox__field {
    width: 19px;
    height: 19px;
    border-radius: 2.4px;
    background-color: #fff;
    border: solid 1.2px #e6e9ee;
}

.md-checkbox input[type='checkbox']:not(:checked) + .md-checkbox__field {
    background-color: #fff;
    border: solid 1.2px #f90;
}

.md-checkbox input[type='checkbox']:checked + .md-checkbox__field {
    background-color: #f90;
    border-color: #f90;
}

.md-checkbox__field {
    position: relative;
}

.md-checkbox__field:after {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    margin-right: auto;
    margin-left: auto;
    width: 8px;
    height: 13px;
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
    transform: rotate(45deg);
    content: '';
}

/* number input */

.number-input {
    position: relative;
    display: flex;
}

.number-input--simple {
    height: 32px;
}

.number-input--simple input{
    width: 100%;
    padding: 8px 32px 8px 16px;
    border-width: 0;
    font-size: 14px;
    background-color: #f9f9f9;
}

.number-input--simple .btn {
    position: absolute;
    right: 8px;
    width: 8px;
    height: 6px;
    padding: 0;
    border: none;
    font-size: 0;
    background-color: transparent;
}

.number-input--simple .btn--increase {
    top: 8px;
}

.number-input--simple .btn--decrease {
    bottom: 8px;
}

.number-input--simple .btn::after {
    position: absolute;
    top: 0;
    left: 0;
    border-style: solid;
    content: "";
}

.number-input--simple .btn--increase::after {
    border-width: 0 4px 6px;
    border-color: transparent transparent #919da8 transparent;
}

.number-input--simple .btn--decrease::after {
    border-width: 6px 4px 0;
    border-color: #919da8 transparent transparent transparent;
}

.btn--increase:hover::after,
.btn--increase:focus::after {
    border-color: transparent transparent #4e6173 transparent;
}

.btn--decrease:hover::after,
.btn--decrease:focus::after {
    border-color: #4e6173 transparent transparent transparent;
}

.number-input--orange {
    justify-content: space-between;
    align-items: center;
    height: 30px;
    min-width: 90px;
    border: 1px solid #f90;
    border-radius: 1px;
}

.number-input--orange .btn {
    height: 100%;
    padding: 0;
    margin-right: 10px;
    margin-left: 10px;
    border: none;
    font-family: Montserrat, sans-serif;
    font-size: 22px;
    font-weight: 600;
    color: rgba(254, 145, 2, 0.54);
    line-height: 1;
    background: none;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
}

.number-input--orange .btn:active,
.number-input--orange .btn:hover {
    color: #f90;
}

.number-input--orange input {
    width: 20px;
    border: none;
    font-family: Montserrat, sans-serif;
    font-size: 12px;
    font-weight: 600;
    color: rgba(52, 73, 94, 0.87);
    text-align: center;
}

/* chips */

.chips__list {
    display: flex;
    flex-wrap: wrap;
}

.chips__list>li>*{vertical-align: middle}

.chips__item {
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 5px;

    color: black;

}

.chips__item:last-child {
    margin-left: 0;
}

.chips__input-container {
    flex-grow: 1;
    flex-basis: 200px;
}

.chips__input {
    width: 194px;
    padding:0px 3px;
    border: 1px solid #f90;
}

.chips__hint {
    color: #34495e;
}

/* radio button */

.md-radio {
    position: relative;
    font-size: 14px;
}

.md-radio input[type='radio'] {
    position: absolute;
    left: -9999px;
    opacity: 0;
}

.md-radio span {
    position: relative;
    padding-left: 25px;
    color: rgba(44, 62, 80, 0.54);
}

.md-radio input[type='radio']:checked + span {
    color: #f90;
}

.md-radio input[type='radio']:checked + span::before {
    border-color: #f90;
}

.md-radio input[type='radio']:checked + span::after {
    transform: scale(1);
}

.md-radio span:after,
.md-radio span:before {
    position: absolute;
    border-radius: 100%;
    content: '';
}

.md-radio span:before {
    top: 0;
    left: 0;
    width: 18px;
    height: 18px;
    border: 1px solid rgba(44, 62, 80, 0.54);
}

.md-radio span:after {
    top: 5px;
    left: 5px;
    width: 8px;
    height: 8px;
    background-color: #f90;
    transform: scale(0);
    transition: transform 0.5s ease;
}

/* raido switcher */

.radio-switcher {
    display: flex;
    border-radius: 4px;
    overflow: hidden;
}

.radio-switcher__item {
    position: relative;
    cursor: pointer;
}

.radio-switcher__item.active {
    cursor: default;
}

.radio-switcher--buttons {
    position: relative;
}

.radio-switcher--buttons:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #e7e9ec;
    content: "";
}

.radio-switcher--buttons .radio-switcher__item {
    z-index: 1;
    flex: 1;
    padding: 8px 10px;
    border-radius: 4px;
    font-size: 14px;
    color: #919da8;
    text-align: center;
}

.radio-switcher--buttons .radio-switcher__item.active {
    font-family: RobotoMedium, sans-serif;
    color: #fff;
    background-color: #f90;
}

/* select */

.md-select {
    padding: 5px 8px;
    border-radius: 2px;
    border-color: #e5e5e5;
    font-size: 12px;
    color: #4e6173;
}

.ui-select-match-text,
.ui-select-placeholder {
    padding-right: 10px;
}

.ui-select-bootstrap .ui-select-toggle > .caret {
    color: rgba(52, 73, 94, 0.54);
}

.ui-select-search,
.ui-select-match.btn-default-focus,
.ui-select-match .ui-select-toggle,
.ui-select-match .ui-select-toggle:hover,
.ui-select-match .ui-select-toggle:focus,
.ui-select-match .ui-select-toggle:active {
    border-radius: 2px;
    font-size: 12px;
    color: #4e6173;
    text-align: left;
    background-color: transparent;

}
.btn-default-focus{
    box-shadow:none;
}

.ui-select-search:focus {
    border-color: #e5e5e5;
}

.ui-select-choices-row {
    padding: 5px 8px;
    font-size: 12px;
    color: #919da8;
    cursor: pointer;
}

.ui-select-choices-row.active {
    color:#fe9102;
    cursor: default;
}

.ui-select-match-text {
    display: block;
    width: 100%;
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
}

.ui-select-allow-clear ~ .caret  {
    display: none;
}

.ui-select-allow-clear ~ .btn-link {
    position: absolute;
    top: 0;
    right: 0;
    display: table;
    height: 100%;
    margin-right: 0!important;
}

.ui-select-allow-clear ~ .btn-link i {
    width: 11px;
    height: 11px;
    vertical-align: middle;
    transition: transform .25s linear;
}


.ui-select-allow-clear ~ .btn-link:hover i {
    transform: scale(1.5);
}

.ui-select-allow-clear ~ .btn-link i:after,
.ui-select-allow-clear ~ .btn-link i::before {
    position: absolute;
    top: 0;
    left: 5px;
    width: 1px;
    height: 100%;
    background-color: rgba(52, 73, 94, 0.87);
    content: "";
}

.ui-select-allow-clear ~ .btn-link i:after {
    transform: rotateZ(45deg)
}

.ui-select-allow-clear ~ .btn-link i::before {
    transform: rotateZ(-45deg)
}

.ui-select-search-hidden {
    display: none;
}

.dropdown-menu {
    padding: 0;
    border-color: #e5e5e5;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.select--gray .ui-select-search,
.select--gray .ui-select-match .ui-select-toggle {
    height: 39px;
    padding: 10px 16px;
    border-color: #f9f9f9;
    font-size: 16px;
    text-align: left;
    background-color: #f9f9f9;
}

.select--gray .ui-select-match .ui-select-toggle:hover,
.select--gray .ui-select-match .ui-select-toggle:focus,
.select--gray .ui-select-match .ui-select-toggle:active {
    border-color: #f90;
}

.select--gray .ui-select-match.btn-default-focus {
    outline: none;
    box-shadow: none;
}

/* tree */

treecontrol.tree-light li>i.tree-branch-head {
    position: relative;
    width: 10px;
    height: 10px;
    padding: 0!important;
    margin-right: 4px;
}

treecontrol.tree-light li>i.tree-branch-head::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-style: solid;
    border-width: 1px;
    border-radius: 2px;
    font-family: Montserrat, sans-serif;
    font-size: 10px;
    font-style: normal;
    text-align: center;
    line-height: 8px;
}

treecontrol.tree-light li.tree-collapsed>i.tree-branch-head,
treecontrol.tree-light li.tree-expanded>i.tree-branch-head {
    background-image: none;
}

treecontrol.tree-light li.tree-collapsed>i.tree-branch-head:after {
    border-color: #f90;
    color: #fff;
    background-color: #f90;
    content: "+";
}
treecontrol.tree-light li.tree-expanded>i.tree-branch-head:after {
    border-color: #b2bac2;
    border-radius: 2px;
    color: #b2bac2;
    background-color: transparent;
    content: "-";
}

treecontrol li.tree-expanded i.tree-leaf-head,
treecontrol li.tree-collapsed i.tree-leaf-head {
    display: none;
}
treecontrol li.tree-expanded i.tree-branch-head,
treecontrol li.tree-collapsed i.tree-branch-head {
    display: inline-block;
}
treecontrol li.tree-leaf i.tree-branch-head {
    display: none;
}
treecontrol li.tree-leaf i.tree-leaf-head {
    display: inline-block;
}

.md-select__container {
    position: relative;
}

.md-select__list {
    position: absolute;
    top: 31px;
    left: 0;
    z-index: 1;
    width: 100%;
    max-height: 300px;
    border: 1px solid #e5e5e5;
    background-color: #fff;
    overflow: auto;
}

.md-select__item {
    padding: 8px 7px 7px 7px;
    font-size: 0.8571rem;
    color: #b2bac2;
    cursor: pointer;
}

.md-select__item:hover,
.md-select__item--selected {
    color: #4e6173;
    background-color: #e7ecee;
}

.md-select__item--selected {
    cursor: default;
}

.form-inline {
    margin-bottom: 16px;
}
