.btn {
    font-family: Roboto;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
}

.btn-sm{padding: 3px 7px;}


.btn:hover,
.btn:active,
.btn:visited,
.btn.active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
}

.btn__default {
    border: 1px solid transparent;
    border-radius: 5px;
    font-family: RobotoMedium;
    transition: background-color 0.5s ease, color 0.5s ease;
}

.btn--orange {
    border-color: #f90;
    background-color: #f90;
    color: #fff;
}

.btn--white-orange {
    border-color: #f90;
    background-color: #fff;
    color: #f90;
}

.btn--orange:hover,
.btn--orange:focus {
    color: #fff
}

.btn--white-orange:hover,
.btn--white-orange:focus {
    color: #f90;
}


.btn--text {
    border-color: transparent;
    font-family: RobotoBold, sans-serif;
    color: #f90;
    background-color: transparent;
}

.btn--text:hover,
.btn--text:focus {
    color: #f90;
    border-color: #f90;
}

.btn__with-icon {
    display: inline-block;
    width: 24px;
    height: 24px;
    padding: 4px;
    border-width: 0;
    line-height: 1rem;
    background-color: transparent;
}

.btn__icon {
    padding: 0;
}

.btn--chips {
    padding: 6px;
    position: relative;
    top: -7px;
    right: 4px;
    width: 24px;
    height: 24px;
    border: none;
    border-radius: 24px;
    line-height: 1;
    text-align: center;
    background-color: #fff;
}

.btn--chips .svg {
    display: inline-block;

}

.btn__checkbox,
.btn__rounded {
    height: 20px;
    border: 1px solid #919DA8;
    border-radius: 10px;
    font-size: 13px;
    line-height: 7px;
    color:#919DA8;
}

.btn__checkbox:not(.btn__checkbox--active):hover {
    color: #919da8;
}

.btn__rounded:hover {
    font-family: RobotoMedium;
    color: #fff;
    background-color: #f90;
}

.btn__checkbox--active {
    font-family: RobotoMedium;
    background-color: #919DA8;
    color:white;

}

.btn__checkbox--active:hover {
    color: #fff;
}

.btn__checkbox input {
    position: absolute;
    left: -9999px;
    opacity: 0;
}

.btn__menu,
.btn__create {
    width: 56px;
    height: 56px;
}
.btn__create {
    padding: 16px;
    border-width: 0;
    border-radius: 56px;
    background-image: linear-gradient(180deg, #f90 4.17%, #ffb84d 100%);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.24), 0px 2px 4px rgba(0, 0, 0, 0.12);
}

.btn__menu {
    padding: 18px 12px;
    background-color: #fff;
}

.btn__create:hover,
.btn__create:active {
    background-image: linear-gradient(180deg, #da883f 4.17%, #ffb84d 100%);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24);
}

.btn__create .svg {
    fill: #fff;
}

.btn__show-more {
    position: relative;
    width: 10px;
    height: 5px;
    padding: 0;
    background-color: transparent;
}

.btn__show-more:before {
    position: absolute;
    top: 0;
    left: 0;
    border-width: 5px 5px 0;
    border-style: solid;
    border-color: #919da8 transparent transparent transparent;
    content: "";
}

.btn__show-more--small {
    width: 7px;
    height: 3px;
}

.btn__show-more--small:before {
    border-width: 3px 3.5px 0;
}

.btn--without-border {
    border-width: 0;
    background-color: transparent;
}

.pagination__container {
    display: flex;
    align-items: center;
}

.pagination__btn-control {
    position: relative;
    width: 5px;
    height: 9px;
    padding: 0;
    border-width: 0;
    font-size: 0;
    background-color: transparent;
}

.pagination__btn-control--left {
    margin-right: 12px;
}

.pagination__btn-control--right {
    margin-left: 12px;
}

.pagination__btn-control--right .svg {
    transform: rotate(180deg);
}

.pagination__btn-control.disabled,
.pagination__btn-control[disabled] {
    cursor: not-allowed;
    filter: alpha(opacity=65);
    opacity: 0.65;
}

.pagination__btn-page {
    position: relative;
    height: 17px;
    padding-right: 5px;
    padding-left: 5px;
    border-width: 0;
    border-radius: 2px;
    font-size: 1rem;
    line-height: 1.143rem;
    color: rgba(52, 73, 94, 0.87);
    background-color: transparent;
}

.pagination__btn-page:not(:last-child) {
    margin-right: 2px;
}

.pagination__btn-page--active {
    color: #fff;
    background-color: #f90;
}
