.ngdialog.ngdialog-theme-progressive {
  padding-bottom: 160px;
  padding-top: 160px;
}

.ngdialog.ngdialog-theme-progressive .ngdialog-content {
  background: #fff;
  color: #444;
  font-family: 'Helvetica Neue',sans-serif;
  /*font-size: 1.1em;*/
  font-size: 13px;
  line-height: 1.5em;
  margin: auto;
  max-width: 100%;
  /*padding: 1em;*/
  position: relative;
  width: 960px;
}

.ngdialog.ngdialog-theme-progressive .ngdialog-content h1,
.ngdialog.ngdialog-theme-progressive .ngdialog-content h2,
.ngdialog.ngdialog-theme-progressive .ngdialog-content h3,
.ngdialog.ngdialog-theme-progressive .ngdialog-content h4,
.ngdialog.ngdialog-theme-progressive .ngdialog-content h5,
.ngdialog.ngdialog-theme-progressive .ngdialog-content h6,
.ngdialog.ngdialog-theme-progressive .ngdialog-content p,
.ngdialog.ngdialog-theme-progressive .ngdialog-content ul,
.ngdialog.ngdialog-theme-progressive .ngdialog-content li {
  color: inherit;
}

.ngdialog.ngdialog-theme-progressive .ngdialog-close {
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
}

.ngdialog.ngdialog-theme-progressive .ngdialog-close:before {
  background: transparent;
  color: #bbb;
  content: "\00D7";
  font-size: 26px;
  font-weight: 400;
  height: 30px;
  line-height: 26px;
  position: absolute;
  right: 3px;
  text-align: center;
  top: 3px;
  width: 30px;
}

.ngdialog.ngdialog-theme-progressive .ngdialog-close:hover:before,
.ngdialog.ngdialog-theme-progressive .ngdialog-close:active:before {
  color: #777;
}

.ngdialog.ngdialog-theme-progressive .ngdialog-message {
  margin-bottom: .5em;
}

.ngdialog.ngdialog-theme-progressive .ngdialog-input {
  margin-bottom: 1em;
}

.ngdialog.ngdialog-theme-progressive .ngdialog-input textarea,
.ngdialog.ngdialog-theme-progressive .ngdialog-input input[type="text"],
.ngdialog.ngdialog-theme-progressive .ngdialog-input input[type="password"],
.ngdialog.ngdialog-theme-progressive .ngdialog-input input[type="email"],
.ngdialog.ngdialog-theme-progressive .ngdialog-input input[type="url"] {
  background: #f0f0f0;
  border: 0;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  margin: 0 0 .25em;
  min-height: 2.5em;
  padding: .25em .67em;
  width: 100%;
}

.ngdialog.ngdialog-theme-progressive .ngdialog-input textarea:focus,
.ngdialog.ngdialog-theme-progressive .ngdialog-input input[type="text"]:focus,
.ngdialog.ngdialog-theme-progressive .ngdialog-input input[type="password"]:focus,
.ngdialog.ngdialog-theme-progressive .ngdialog-input input[type="email"]:focus,
.ngdialog.ngdialog-theme-progressive .ngdialog-input input[type="url"]:focus {
  -webkit-box-shadow: inset 0 0 0 2px rgba(0,0,0,0.2);
  box-shadow: inset 0 0 0 2px rgba(0,0,0,0.2);
  outline: none;
}

.ngdialog.ngdialog-theme-progressive .ngdialog-buttons:after {
  clear: both;
  content: '';
  display: table;
}

.ngdialog.ngdialog-theme-progressive .ngdialog-button {
  border: 0;
  cursor: pointer;
  float: right;
  font-family: inherit;
  font-size: .8em;
  letter-spacing: .1em;
  line-height: 1em;
  margin: 0 0 0 .5em;
  padding: .75em 2em;
  text-transform: uppercase;
}

.ngdialog.ngdialog-theme-progressive .ngdialog-button:focus {
  -webkit-animation: ngdialog-pulse 1.1s infinite;
  animation: ngdialog-pulse 1.1s infinite;
  outline: none;
}

@media (max-width: 568px) {
  .ngdialog.ngdialog-theme-progressive .ngdialog-button:focus {
    -webkit-animation: none;
    animation: none;
  }
}

.ngdialog.ngdialog-theme-progressive .ngdialog-button.ngdialog-button-primary {
  background: #3288e6;
  color: #fff;
}

.ngdialog.ngdialog-theme-progressive .ngdialog-button.ngdialog-button-secondary {
  background: #e0e0e0;
  color: #777;
}
.ngdialog.ngdialog-theme-progressive .client-form header {
  font-size: 14px;
  padding: 4px 10px 4px 12px;
}
/*.ngdialog.ngdialog-theme-progressive .smart-form header {*/
  /*padding: 0;*/
/*}*/
/*.ngdialog.ngdialog-theme-progressive .smart-form.well {*/
  /*border: none;*/
/*}*/
.ngdialog.ngdialog-theme-progressive .name-frame{
 display: inline-block;
}
.ngdialog.ngdialog-theme-progressive .progressive-form.well{
  border: none;
}
.ngdialog.ngdialog-theme-progressive .progressive-form.well header{
  border: none;
}
.ngdialog.ngdialog-theme-progressive .progressive-form footer {
  display: block;
  padding: 7px 0px 15px;
  /*border-top: 1px solid rgba(0, 0, 0, .1);*/
  /*background: rgba(248, 248, 248, .9);*/
}
.ngdialog.ngdialog-theme-progressive .progressive-form footer .btn {
  float: right;
  height: 31px;
  margin: 10px 0 0 5px;
  padding: 0 22px;
  cursor: pointer;
}
.ngdialog.ngdialog-theme-progressive .progressive-form footer .btn a{
    color: #fff;
    text-decoration: none;
}

.ngdialog.ngdialog-theme-progressive .progressive-form footer:after {
  content: '';
  display: table;
  clear: both;
}
/*.ngdialog.ngdialog-theme-progressive div.hover:hover{*/
  /*background-color: #f1f1f1;*/
/*}*/
.ngdialog.ngdialog-theme-progressive .vertical-align{
  display: flex;
  flex-direction: row;
}
.ngdialog.ngdialog-theme-progressive .container-center{
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 15px;
  /*width: 100%;*/
}
.ngdialog.ngdialog-theme-progressive .container-center .file-block{
  color: #0E6724;
}
.ngdialog.ngdialog-theme-progressive .container-center .file-block .file-icon{
  text-align: center;
}
.ngdialog.ngdialog-theme-progressive .container-center .file-block .file-name{
 padding-top: 30px;
}
.ngdialog.ngdialog-theme-progressive .left-column{
  border-right: 1px dotted #e9e9e9;
  padding-right: 0;
}
.ngdialog.ngdialog-theme-progressive .title-section{
  font-weight: bold;
  padding-top:15px;
  padding-bottom: 10px;
  border-bottom:1px dotted #e9e9e9;
}
.ngdialog.ngdialog-theme-progressive .item-section{
  padding-top: 8px;
  padding-bottom: 5px;
  cursor: pointer;
  color: #0E6724;
}
.ngdialog.ngdialog-theme-progressive .item-section.active{
  background-color: #f1f1f1;
}
.ngdialog.ngdialog-theme-progressive .item-section:hover{
  background-color: #f1f1f1;
}
.ngdialog.ngdialog-theme-progressive .item-icon{
  position: absolute;
  font-size: 16px;
}
.ngdialog.ngdialog-theme-progressive .item-content{
  padding-left: 20px;
  word-wrap: break-word;
}
.ngdialog.ngdialog-theme-progressive div.disabled{
  opacity: 0.8;
}
.ngdialog.ngdialog-theme-progressive div.executorsTree{
  max-height: 600px;
  overflow-y: auto;
}
.ngdialog.ngdialog-theme-progressive .executorsTree .dropdownInput{
  display: flex;
  align-items: center;
}
.ngdialog.ngdialog-theme-progressive .executorsTree .dropdownInput label{
  font-weight: bold;
  width:180px;
}
.ngdialog.ngdialog-theme-progressive .executorsTree .searchInput{
  padding-top: 10px;
  margin: 0;
  display: flex;
  padding-bottom: 20px;
}
.ngdialog.ngdialog-theme-progressive .executorsTree .searchInput input{
  height: 34px;
  padding-left: 15px;
}
.ngdialog.ngdialog-theme-progressive .executorsTree .searchInput span{
  width: 78px;
  padding-left: 0;
}
.ngdialog.ngdialog-theme-progressive .executorsTree .sapInput label{
  font-weight: bold;
  padding-top: 20px;
}
.ngdialog.ngdialog-theme-progressive .executorsTree .bottomButtons{
  height: 70px;
  padding: 10px;
  margin-top: 50px;
}
.ngdialog.ngdialog-theme-progressive panel.executorsTree .selectedShopsNum{
  padding-left: 20px;
  padding-top: 20px;
}
