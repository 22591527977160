
.jarviswidget > header{color:#fe9102;}
a{color:#D97833}
a:hover{color:orange;}

table.styled tbody tr:hover{background-color: rgba(217,120,51,.1)}
table.styled tbody tr.active td{background-color: rgba(217,120,51,.2) !important}

panel>content>ul.buttons>li:hover{background-color: rgba(217,120,51,.1)}
panel>content>ul.buttons>li.active{background-color: rgba(217,120,51,.2)}


.nav-tabs > li.active > a{box-shadow: 0 -2px 0  #fe9102;}

/*.tabs-nav li.active a{
    color:#fe9102;
}*/

.notMd{display:none;}

.only.md{display:inline-block !important;}
.only.md.block{display:block !important;}
.only.md.block.hidden{display:none !important;}
td.only.md{display:table-cell !important;}
tr.only.md{display:table-row !important;}

table.styled thead td>sort.active:after{color:#fe9102;;}
table.styled thead td>sort.active.reverse:before{color:#fe9102;}


.required:after{content:"*"}

nav ul.pagination>li>a{color:#60A914 !important;}
nav ul.pagination li.active a{color:white !important;background-color:#fe9102 !important;}

.header-searching button:hover,
.header-searching button:active,
.header-searching button:visited {
	color: #fe9102;
}
panel>header {
	color: #fe9102;
}

.btn-primary, button.branded, .btn-primary:focus {background-color:#FF9900;border-color:#FF9900}
.btn-primary:hover {border-color:#FFB84D;background-color: #FFB84D}
.btn-primary:active, .btn-primary.active {background-color: #ED9140 !important;border-color:#ED9140 !important;}

.btn-primary:disabled{background-color: lightgray;color:gray;border-color:gray;}
.btn-primary:disabled:hover{background-color: lightgray;color:#b3b3b3;border-color:#b3b3b3;}

.btn-primary.reverse:disabled{background-color: lightgray;color:gray;border-color:gray;}
.btn-primary.reverse:disabled:hover{background-color: lightgray;color:#b3b3b3;border-color:#b3b3b3;}

.btn-primary.reverse, button.branded.reverse{
    background-color:white;
    color:#FF9900;
    border-color:#FF9900;
    background-image:none;
}
.btn-primary.reverse:hover{
    color: #FFB84D;
    border-color:#FFB84D;
}

.btn-primary.reverse:active{
    position:relative;
    border-color:#ED9140;
    color:#ED9140;
    background-color:white !important;
}


.btn-white{
    background-color: white;
    border:none;
    color:#4E6173;
}


logoPanel,
table>caption {
    background-color: #fe9102;
    color: white;
}
header span#logo{background:url("../img/md_logo.png") no-repeat;background-size: contain;margin-top:2px !important;height:45px;}

mdAuditLogo {
	display: block;
  height: 187px;
	width: 153px;
  background: url("../img/mdaudit-logo-new.png") no-repeat center;
  background-size: contain;
}

#logout a, #goBack a{cursor:pointer !important;border: none;background-image: linear-gradient(to bottom, #fe9102, #985813) !important; background-color: #985813 !important;}
div#logout span a{background-color: #60a914;background-image: linear-gradient(to bottom, #fe9102, #985813);border: 1px solid orange;color: #fff !important;   text-shadow: 0 -1px #985813;}
rotator{background: url("../img/ajax-loaders/loader-smallfive.gif")}


.smart-form label.checkbox>i::after{border-color:orange;color: orange !important}
.smart-form i.icon-append{color: orange !important}
.smart-form input:focus {border-color: orange !important}


nav ul.pagination li.active a, nav ul.pagination li.active:hover a{border-color:#985813}

.smart-style-3 .btn-header > *:first-child > a{background-image: linear-gradient(to bottom, #fe9102, #985813);border-color:#985813}


table.shopsList thead tr:nth-child(3) td{text-align: center;height:70px;width:20px;}
table.shopsList thead tr:nth-child(3) td>span{position:relative;vertical-align: middle;}
table.shopsList td.bool{padding:1px;text-align: center}
table.shopsList thead tr:nth-child(3) td>span>span{

		transform:rotate(-90deg);
		position:absolute;width:70px;bottom:-9px;left:-37px;text-align:center;
	}
form.edit.shop  div.checkboxes label{margin-right:5px;}
form.edit.shop  div.checkboxes label:last-of-type{margin-right:0px;}

.select2-search::before{color:#985813 !important}


.editChecklist summary{margin-right: 20px}
.editChecklist summary.none{color:red !important;font-weight:bold;}
.editChecklist summary.all{color:orange !important;font-weight:bold;}
.editChecklist summary filled{color:inherit !important;}


.dashboard .dragFrom span.store{position:relative;}
.dashboard .dragFrom span.store>limits{position:absolute;display:inline-block;bottom:0px;left:5px;font-weight:bolder;}
.dashboard .dragFrom span.store>limits>done{margin-right:1px}
.dashboard .dragFrom span.store>limits>from{margin-left:1px;}




.filters button.btn-info.active{background-image: linear-gradient(to bottom, #8CC63F,#557a23);border:green;}
.filters button.btn-info.active>span{color:white;}
.select2-drop-active{border-color: lightgray !important;border-bottom-width: 1px !important}

.select2-container-multi .select2-choices .select2-search-choice{background-color: #E98505;border-color:#fe9102;}

.select2-results .select2-highlighted{background-color: #E98505;}
thead.sortableRich sort.active{color: #985813;}
.select2-container-multi.select2-container-active .select2-choices{border: 1px solid lightgray !important;}

body .reportFilters filter .form-control{border-color: #fe9102;}
body .reportFilters filter .input-group-btn button{border-color: #fe9102;}
body .reportFilters filter .input-group-btn button:hover{color:#fe9102;}
body .reportFilters filter .ui-select-choices-row:hover{color:#fe9102;}
body .reportFilters filter .select2-container-multi .select2-choices{border-color: #fe9102}

pokazometr>line>div>fill{background-color:#FF9900;}
pokazometr.full>line>div>fill{background-color:#66BB6A;}

taskFullInfo table>caption, page#taskFull shortInfo{color: #fe9102;background-color: white}



page#taskFull taskFullInfo showFull.up{background-image: url("../img/arrow.png");transform: rotate(180deg);}
page#taskFull taskFullInfo showFull.down{background-image: url("../img/arrow.png");}

pagination.pagination>*{vertical-align: middle}
pagination.pagination>li>a, pagination.pagination>li>span{padding: 1px 7px;box-shadow: none !important;font-size: 13px;}
pagination.pagination>li.pagination-next>a, pagination.pagination>li.pagination-prev>a{padding:1px 4px;}
pagination.pagination>li>a {border: none !important;color: rgba(52, 73, 94, 0.87);border-radius: 4px;}
pagination.pagination>li{display: inline-block;cursor: pointer;margin-right: 4px;}
pagination.pagination>.active>a{background-color: #E98505;}
pagination.pagination>.active>a:hover{background-color: #E98505;}
pagination .pagination-prev.disabled+li {margin-left: 1px;}
pagination .pagination-prev.disabled,
pagination .pagination-next.disabled { display: none; }


checklist zones .container-element h3{background-color: #E98505;}
