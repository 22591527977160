.ngdialog.ngdialog-theme-mine {
    padding-bottom: 100px;
    padding-top: 100px;
}

.ngdialog.ngdialog-theme-mine .ngdialog-content {
    background: #fff;
    color: #444;
    font-family: 'Helvetica Neue',sans-serif;
    font-size: 1.1em;
    line-height: 1.5em;
    margin: 0 auto;
    max-width: 100%;
    padding: 1em;
    position: relative;
    width: 900px;
}

.ngdialog.ngdialog-theme-mine .ngdialog-content h1,
.ngdialog.ngdialog-theme-mine .ngdialog-content h2,
.ngdialog.ngdialog-theme-mine .ngdialog-content h3,
.ngdialog.ngdialog-theme-mine .ngdialog-content h4,
.ngdialog.ngdialog-theme-mine .ngdialog-content h5,
.ngdialog.ngdialog-theme-mine .ngdialog-content h6,
.ngdialog.ngdialog-theme-mine .ngdialog-content p,
.ngdialog.ngdialog-theme-mine .ngdialog-content ul,
.ngdialog.ngdialog-theme-mine .ngdialog-content li {
    color: inherit;
}

.ngdialog.ngdialog-theme-mine .ngdialog-close {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
}

.ngdialog.ngdialog-theme-mine .ngdialog-close:before {
    background: transparent;
    color: #bbb;
    content: "\00D7";
    font-size: 26px;
    font-weight: 400;
    height: 30px;
    line-height: 26px;
    position: absolute;
    right: 3px;
    text-align: center;
    top: 3px;
    width: 30px;
}

.ngdialog.ngdialog-theme-mine .ngdialog-close:hover:before,
.ngdialog.ngdialog-theme-mine .ngdialog-close:active:before {
    color: #777;
}

.ngdialog.ngdialog-theme-mine .ngdialog-message {
    margin-bottom: .5em;
}

.ngdialog.ngdialog-theme-mine .ngdialog-input {
    margin-bottom: 1em;
}

.ngdialog.ngdialog-theme-mine .ngdialog-input textarea,
.ngdialog.ngdialog-theme-mine .ngdialog-input input[type="text"],
.ngdialog.ngdialog-theme-mine .ngdialog-input input[type="password"],
.ngdialog.ngdialog-theme-mine .ngdialog-input input[type="email"],
.ngdialog.ngdialog-theme-mine .ngdialog-input input[type="url"] {
    background: #f0f0f0;
    border: 0;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    margin: 0 0 .25em;
    min-height: 2.5em;
    padding: .25em .67em;
    width: 100%;
}

.ngdialog.ngdialog-theme-mine .ngdialog-input textarea:focus,
.ngdialog.ngdialog-theme-mine .ngdialog-input input[type="text"]:focus,
.ngdialog.ngdialog-theme-mine .ngdialog-input input[type="password"]:focus,
.ngdialog.ngdialog-theme-mine .ngdialog-input input[type="email"]:focus,
.ngdialog.ngdialog-theme-mine .ngdialog-input input[type="url"]:focus {
    -webkit-box-shadow: inset 0 0 0 2px rgba(0,0,0,0.2);
    box-shadow: inset 0 0 0 2px rgba(0,0,0,0.2);
    outline: none;
}

.ngdialog.ngdialog-theme-mine .ngdialog-buttons:after {
    clear: both;
    content: '';
    display: table;
}

.ngdialog.ngdialog-theme-mine .ngdialog-button {
    border: 0;
    cursor: pointer;
    float: right;
    font-family: inherit;
    font-size: .8em;
    letter-spacing: .1em;
    line-height: 1em;
    margin: 0 0 0 .5em;
    padding: .75em 2em;
    text-transform: uppercase;
}

.ngdialog.ngdialog-theme-mine .ngdialog-button:focus {
    -webkit-animation: ngdialog-pulse 1.1s infinite;
    animation: ngdialog-pulse 1.1s infinite;
    outline: none;
}

@media (max-width: 568px) {
    .ngdialog.ngdialog-theme-mine .ngdialog-button:focus {
        -webkit-animation: none;
        animation: none;
    }
}

.ngdialog.ngdialog-theme-mine .ngdialog-button.ngdialog-button-primary {
    background: #3288e6;
    color: #fff;
}

.ngdialog.ngdialog-theme-mine .ngdialog-button.ngdialog-button-secondary {
    background: #e0e0e0;
    color: #777;
}