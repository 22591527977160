@font-face {
  font-family: 'mdIcons';
  src:  url('../fonts/mdIcons.eot?scx9dv');
  src:  url('../fonts/mdIcons.eot?scx9dv#iefix') format('embedded-opentype'),
    url('../fonts/mdIcons.ttf?scx9dv') format('truetype'),
    url('../fonts/mdIcons.woff?scx9dv') format('woff'),
    url('../fonts/mdIcons.svg?scx9dv#mdIcons') format('svg');
  font-weight: normal;
  font-style: normal;

}

[class^="mdIcon-"], [class*=" mdIcon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'mdIcons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
    font-size:100%;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.mdIcon-shop:before {
  content: "\e900";
}
.mdIcon-search:before {
  content: "\e901";
}
.mdIcon-filter:before {
  content: "\e902";
}
.mdIcon-executor:before {
  content: "\e903";
}
.mdIcon-close:before {
  content: "\e904";
}
.mdIcon-arrowRight:before {
  content: "\e905";
}
